// import React, { useEffect, useState } from "react";
// import ReactHtmlParser from 'html-react-parser'; 
// import ReactDOM from "react-dom";
import Tylo_icon from "../../assets/images/Tylo_logo.svg";
// import Tracker_close from "../../assets/images/tracker-close.svg";
// import Dropdown_open from "../../assets/images/Dropdown_open.svg" ;
// import Dropdown_closed from "../../assets/images/Dropdown_closed.svg";
import "../footer/footer.scss";
// import { gapi } from "gapi-script";
// import axios from "axios";
// import {useNavigate } from "react-router-dom";
// import { setToken } from "../../services/userService";
// import { createClient } from '@supabase/supabase-js';


function Privacy() {

    // const navigate = useNavigate();

    const handlePrivacyPolicyClick = () => {
        window.open(`${window.location.origin}/#privacy`, '_blank');
    };
    const handleTOUClick = () => {
        window.open(`${window.location.origin}/#termsofuse`, '_blank');
    }


    console.log('privacy component about to render JSX');
    return (
        <div className="feature-page">
            <div className="feature-page-icon">
                    <img src={Tylo_icon} alt="Tylo Icon" className="tylo-icon" />
                    <div className="tabs">
                        <div className="top-bar-tab-current_page">
                            <a href="/#features" className="top-bar-text">Features</a> {/* Link to the Features page */}
                        </div>
                        <div className="top-bar-tab">
                            <a href="/#about" className="top-bar-text">About Us</a> {/* Link to the About page */}
                        </div>
                    </div>
                    {/* <img src={googleProfileImageUrl} alt="Profile Icon" className="profile-icon" /> */}
            </div>

          <div className="gradient-rectangle">
            <h2 className="feature-title">Privacy Policy</h2>
            <p className="privacy-update">update Date: 15 November 2023</p>
          </div>
            <div className="privacy-page">
                <div className="privacy-frame-32">
                    <h2 className="privacy-main-header">Scope of Privacy Policy</h2>
                    <p className="privacy-main-text">
                        The data collected from you when you use the Tylo.ai website and services (“Tylo.ai”) is used in line with this Privacy Policy. This Privacy Policy complies with the Data Protection Act 2018 and General Data Protection Regulation (“GDPR”) and other applicable global privacy laws. By accessing Tylo.ai and using it, you consent to the privacy practices of Tylo AI Ltd (“Tylo AI”, “we”, “us” or “our”). 

                        This Privacy Policy is in addition to other applicable policies and agreements applicable to Tylo.ai – some of which are referenced in this document (and also found at the footer of the website). Please, review and agree to them along with this Privacy Policy. 
                    </p>
                    <div className="grey-tab">
                        <p className="privacy-main-bold">
                            Please, note that if you are using Tylo.ai as an organisation’s representative, you represent that this Privacy Policy applies to the organisation and any other individual using it on behalf of the organisation.
                        </p>
                    </div>
                </div>
                <div className="privacy-frame-32">
                    <h2 className="privacy-main-header">Information about us</h2>
                    <p className="privacy-main-text">
                        We are Tylo AI Ltd, a company registered in the United Kingdom (see below for our company information). We are responsible for the data you provide when you use tylo.ai. Under the GDPR and similar privacy laws, we are referred to as your ‘Data Controller’. If you have any concerns, issues, requests, complaints or enquiries regarding this Privacy policy, please use the information below to contact us:
                    </p>
                    <div className="grey-container">
                        <div className="grey-tab">
                            <h2 className="privacy-sub-header">Tylo AI</h2>
                        </div>
                        <div className="grey-tab">
                            <h2 className="privacy-sub-header">Reg No:</h2><p className="privacy-main-bold">14734333</p>
                        </div>
                        <div className="grey-tab">
                            <h2 className="privacy-sub-header">Email:</h2><p className="privacy-main-bold">contact@tylo.ai</p>
                        </div>
                        <div className="grey-tab">
                            <h2 className="privacy-sub-header">Address:</h2><p className="privacy-main-bold">7 Bell Yard, London, England, WC2A 2JR, United Kingdom</p>
                        </div>
                    </div>
                    
                </div>
                <div className="privacy-frame-32">
                    <h2 className="privacy-main-header">The data we collect</h2>
                    <p className="privacy-main-bold">Depending on how you interact with Tylo.ai, we will collect data under the following categories:</p>
                    <div className="grey-container">
                        <div className="grey-tab-full">
                            <div className="circle-check">
                            <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="https://www.w3.org/2000/svg">
    <path d="M16.1078 0.901957C15.8102 0.736394 15.483 0.631107 15.1447 0.592116C14.8065 0.553125 14.4638 0.581194 14.1364 0.67472C13.809 0.768245 13.5033 0.925392 13.2367 1.13717C12.9701 1.34895 12.7478 1.61121 12.5826 1.90896L7.77052 10.5689L5.01649 7.81489C4.77739 7.56732 4.49137 7.36985 4.17514 7.23401C3.8589 7.09816 3.51877 7.02666 3.17461 7.02367C2.83044 7.02068 2.48912 7.08626 2.17057 7.21659C1.85202 7.34692 1.56262 7.53939 1.31924 7.78276C1.07587 8.02613 0.883407 8.31553 0.753078 8.63408C0.622748 8.95263 0.557166 9.29395 0.560156 9.63812C0.563147 9.98229 0.634651 10.3224 0.770497 10.6386C0.906342 10.9549 1.10381 11.2409 1.35137 11.48L6.53542 16.6641C7.02531 17.1552 7.68628 17.4248 8.36798 17.4248L8.72698 17.3989C9.12427 17.3433 9.50324 17.1963 9.83406 16.9694C10.1649 16.7425 10.4385 16.4419 10.6334 16.0912L17.1135 4.42711C17.2789 4.12961 17.3841 3.80244 17.4231 3.46428C17.4621 3.12611 17.4341 2.78358 17.3407 2.45623C17.2473 2.12889 17.0904 1.82315 16.8788 1.55646C16.6673 1.28978 16.4053 1.06738 16.1078 0.901957Z" fill="#4476F1"/>
    </svg>  
                            </div>
                                <p className="privacy-main-bold">Registration and profile data: <p className="privacy-main-text"> This may include your name, organisation name, email address, password, picture and any data you provide when you create an account or update your profile.</p></p>     
                        </div>
                        <div className="grey-tab-full">
                            <div className="circle-check">
                            <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="https://www.w3.org/2000/svg">
    <path d="M16.1078 0.901957C15.8102 0.736394 15.483 0.631107 15.1447 0.592116C14.8065 0.553125 14.4638 0.581194 14.1364 0.67472C13.809 0.768245 13.5033 0.925392 13.2367 1.13717C12.9701 1.34895 12.7478 1.61121 12.5826 1.90896L7.77052 10.5689L5.01649 7.81489C4.77739 7.56732 4.49137 7.36985 4.17514 7.23401C3.8589 7.09816 3.51877 7.02666 3.17461 7.02367C2.83044 7.02068 2.48912 7.08626 2.17057 7.21659C1.85202 7.34692 1.56262 7.53939 1.31924 7.78276C1.07587 8.02613 0.883407 8.31553 0.753078 8.63408C0.622748 8.95263 0.557166 9.29395 0.560156 9.63812C0.563147 9.98229 0.634651 10.3224 0.770497 10.6386C0.906342 10.9549 1.10381 11.2409 1.35137 11.48L6.53542 16.6641C7.02531 17.1552 7.68628 17.4248 8.36798 17.4248L8.72698 17.3989C9.12427 17.3433 9.50324 17.1963 9.83406 16.9694C10.1649 16.7425 10.4385 16.4419 10.6334 16.0912L17.1135 4.42711C17.2789 4.12961 17.3841 3.80244 17.4231 3.46428C17.4621 3.12611 17.4341 2.78358 17.3407 2.45623C17.2473 2.12889 17.0904 1.82315 16.8788 1.55646C16.6673 1.28978 16.4053 1.06738 16.1078 0.901957Z" fill="#4476F1"/>
    </svg>  
                            </div>
                                <p className="privacy-main-bold">Contact data: <p className="privacy-main-text"> This may include the email address submitted as part of your registration information for communication and verification purposes.</p></p>     
                        </div>
                    </div>
                    <div className="grey-container">
                        <div className="grey-tab-full">
                            <div className="circle-check">
                            <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="https://www.w3.org/2000/svg">
    <path d="M16.1078 0.901957C15.8102 0.736394 15.483 0.631107 15.1447 0.592116C14.8065 0.553125 14.4638 0.581194 14.1364 0.67472C13.809 0.768245 13.5033 0.925392 13.2367 1.13717C12.9701 1.34895 12.7478 1.61121 12.5826 1.90896L7.77052 10.5689L5.01649 7.81489C4.77739 7.56732 4.49137 7.36985 4.17514 7.23401C3.8589 7.09816 3.51877 7.02666 3.17461 7.02367C2.83044 7.02068 2.48912 7.08626 2.17057 7.21659C1.85202 7.34692 1.56262 7.53939 1.31924 7.78276C1.07587 8.02613 0.883407 8.31553 0.753078 8.63408C0.622748 8.95263 0.557166 9.29395 0.560156 9.63812C0.563147 9.98229 0.634651 10.3224 0.770497 10.6386C0.906342 10.9549 1.10381 11.2409 1.35137 11.48L6.53542 16.6641C7.02531 17.1552 7.68628 17.4248 8.36798 17.4248L8.72698 17.3989C9.12427 17.3433 9.50324 17.1963 9.83406 16.9694C10.1649 16.7425 10.4385 16.4419 10.6334 16.0912L17.1135 4.42711C17.2789 4.12961 17.3841 3.80244 17.4231 3.46428C17.4621 3.12611 17.4341 2.78358 17.3407 2.45623C17.2473 2.12889 17.0904 1.82315 16.8788 1.55646C16.6673 1.28978 16.4053 1.06738 16.1078 0.901957Z" fill="#4476F1"/>
    </svg>  
                            </div>
                                <p className="privacy-main-bold">Contact data: <p className="privacy-main-text"> This may include your name, organisation name, email address, password, picture and any data you provide when you create an account or update your profile.</p></p>     
                        </div>
                        <div className="grey-tab-full">
                            <div className="circle-check">
                            <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="https://www.w3.org/2000/svg">
    <path d="M16.1078 0.901957C15.8102 0.736394 15.483 0.631107 15.1447 0.592116C14.8065 0.553125 14.4638 0.581194 14.1364 0.67472C13.809 0.768245 13.5033 0.925392 13.2367 1.13717C12.9701 1.34895 12.7478 1.61121 12.5826 1.90896L7.77052 10.5689L5.01649 7.81489C4.77739 7.56732 4.49137 7.36985 4.17514 7.23401C3.8589 7.09816 3.51877 7.02666 3.17461 7.02367C2.83044 7.02068 2.48912 7.08626 2.17057 7.21659C1.85202 7.34692 1.56262 7.53939 1.31924 7.78276C1.07587 8.02613 0.883407 8.31553 0.753078 8.63408C0.622748 8.95263 0.557166 9.29395 0.560156 9.63812C0.563147 9.98229 0.634651 10.3224 0.770497 10.6386C0.906342 10.9549 1.10381 11.2409 1.35137 11.48L6.53542 16.6641C7.02531 17.1552 7.68628 17.4248 8.36798 17.4248L8.72698 17.3989C9.12427 17.3433 9.50324 17.1963 9.83406 16.9694C10.1649 16.7425 10.4385 16.4419 10.6334 16.0912L17.1135 4.42711C17.2789 4.12961 17.3841 3.80244 17.4231 3.46428C17.4621 3.12611 17.4341 2.78358 17.3407 2.45623C17.2473 2.12889 17.0904 1.82315 16.8788 1.55646C16.6673 1.28978 16.4053 1.06738 16.1078 0.901957Z" fill="#4476F1"/>
    </svg>  
                            </div>
                                <p className="privacy-main-bold">Payment method data: <p className="privacy-main-text"> This may include any data contained in any payment method you provide when you subscribe to use Tylo.ai (for example, if you use a credit/debit card, we may ask for your card numbers and billing address).</p></p>     
                        </div>
                    </div>
                    <div className="grey-container">
                        <div className="grey-tab-full">
                            <div className="circle-check">
                            <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="https://www.w3.org/2000/svg">
    <path d="M16.1078 0.901957C15.8102 0.736394 15.483 0.631107 15.1447 0.592116C14.8065 0.553125 14.4638 0.581194 14.1364 0.67472C13.809 0.768245 13.5033 0.925392 13.2367 1.13717C12.9701 1.34895 12.7478 1.61121 12.5826 1.90896L7.77052 10.5689L5.01649 7.81489C4.77739 7.56732 4.49137 7.36985 4.17514 7.23401C3.8589 7.09816 3.51877 7.02666 3.17461 7.02367C2.83044 7.02068 2.48912 7.08626 2.17057 7.21659C1.85202 7.34692 1.56262 7.53939 1.31924 7.78276C1.07587 8.02613 0.883407 8.31553 0.753078 8.63408C0.622748 8.95263 0.557166 9.29395 0.560156 9.63812C0.563147 9.98229 0.634651 10.3224 0.770497 10.6386C0.906342 10.9549 1.10381 11.2409 1.35137 11.48L6.53542 16.6641C7.02531 17.1552 7.68628 17.4248 8.36798 17.4248L8.72698 17.3989C9.12427 17.3433 9.50324 17.1963 9.83406 16.9694C10.1649 16.7425 10.4385 16.4419 10.6334 16.0912L17.1135 4.42711C17.2789 4.12961 17.3841 3.80244 17.4231 3.46428C17.4621 3.12611 17.4341 2.78358 17.3407 2.45623C17.2473 2.12889 17.0904 1.82315 16.8788 1.55646C16.6673 1.28978 16.4053 1.06738 16.1078 0.901957Z" fill="#4476F1"/>
    </svg>  
                            </div>
                                <p className="privacy-main-bold">Transaction data: <p className="privacy-main-text"> This may include data concerning your subscription, including the plan you subscribe to, the payment method used and the time and date of expiry.</p></p>     
                        </div>
                        <div className="grey-tab-full">
                            <div className="circle-check">
                            <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="https://www.w3.org/2000/svg">
    <path d="M16.1078 0.901957C15.8102 0.736394 15.483 0.631107 15.1447 0.592116C14.8065 0.553125 14.4638 0.581194 14.1364 0.67472C13.809 0.768245 13.5033 0.925392 13.2367 1.13717C12.9701 1.34895 12.7478 1.61121 12.5826 1.90896L7.77052 10.5689L5.01649 7.81489C4.77739 7.56732 4.49137 7.36985 4.17514 7.23401C3.8589 7.09816 3.51877 7.02666 3.17461 7.02367C2.83044 7.02068 2.48912 7.08626 2.17057 7.21659C1.85202 7.34692 1.56262 7.53939 1.31924 7.78276C1.07587 8.02613 0.883407 8.31553 0.753078 8.63408C0.622748 8.95263 0.557166 9.29395 0.560156 9.63812C0.563147 9.98229 0.634651 10.3224 0.770497 10.6386C0.906342 10.9549 1.10381 11.2409 1.35137 11.48L6.53542 16.6641C7.02531 17.1552 7.68628 17.4248 8.36798 17.4248L8.72698 17.3989C9.12427 17.3433 9.50324 17.1963 9.83406 16.9694C10.1649 16.7425 10.4385 16.4419 10.6334 16.0912L17.1135 4.42711C17.2789 4.12961 17.3841 3.80244 17.4231 3.46428C17.4621 3.12611 17.4341 2.78358 17.3407 2.45623C17.2473 2.12889 17.0904 1.82315 16.8788 1.55646C16.6673 1.28978 16.4053 1.06738 16.1078 0.901957Z" fill="#4476F1"/>
    </svg>  
                            </div>
                                <p className="privacy-main-bold">Device data: <p className="privacy-main-text"> This may include your device IP Address, geolocation, internet service provider, browser type, operating system and other device identifiers.</p></p>     
                        </div>
                    </div>
                    <div className="grey-container">
                        <div className="grey-tab-full">
                            <div className="circle-check">
                            <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="https://www.w3.org/2000/svg">
    <path d="M16.1078 0.901957C15.8102 0.736394 15.483 0.631107 15.1447 0.592116C14.8065 0.553125 14.4638 0.581194 14.1364 0.67472C13.809 0.768245 13.5033 0.925392 13.2367 1.13717C12.9701 1.34895 12.7478 1.61121 12.5826 1.90896L7.77052 10.5689L5.01649 7.81489C4.77739 7.56732 4.49137 7.36985 4.17514 7.23401C3.8589 7.09816 3.51877 7.02666 3.17461 7.02367C2.83044 7.02068 2.48912 7.08626 2.17057 7.21659C1.85202 7.34692 1.56262 7.53939 1.31924 7.78276C1.07587 8.02613 0.883407 8.31553 0.753078 8.63408C0.622748 8.95263 0.557166 9.29395 0.560156 9.63812C0.563147 9.98229 0.634651 10.3224 0.770497 10.6386C0.906342 10.9549 1.10381 11.2409 1.35137 11.48L6.53542 16.6641C7.02531 17.1552 7.68628 17.4248 8.36798 17.4248L8.72698 17.3989C9.12427 17.3433 9.50324 17.1963 9.83406 16.9694C10.1649 16.7425 10.4385 16.4419 10.6334 16.0912L17.1135 4.42711C17.2789 4.12961 17.3841 3.80244 17.4231 3.46428C17.4621 3.12611 17.4341 2.78358 17.3407 2.45623C17.2473 2.12889 17.0904 1.82315 16.8788 1.55646C16.6673 1.28978 16.4053 1.06738 16.1078 0.901957Z" fill="#4476F1"/>
    </svg>  
                            </div>
                                <p className="privacy-main-bold">Usage and behaviourial data: <p className="privacy-main-text"> This may include data about your search terms, features accessed, duration of use, referring URL and any other anonymous data about your behaviour on Tylo.ai.</p></p>     
                        </div>
                        <div className="grey-tab-full">
                            <div className="circle-check">
                            <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="https://www.w3.org/2000/svg">
    <path d="M16.1078 0.901957C15.8102 0.736394 15.483 0.631107 15.1447 0.592116C14.8065 0.553125 14.4638 0.581194 14.1364 0.67472C13.809 0.768245 13.5033 0.925392 13.2367 1.13717C12.9701 1.34895 12.7478 1.61121 12.5826 1.90896L7.77052 10.5689L5.01649 7.81489C4.77739 7.56732 4.49137 7.36985 4.17514 7.23401C3.8589 7.09816 3.51877 7.02666 3.17461 7.02367C2.83044 7.02068 2.48912 7.08626 2.17057 7.21659C1.85202 7.34692 1.56262 7.53939 1.31924 7.78276C1.07587 8.02613 0.883407 8.31553 0.753078 8.63408C0.622748 8.95263 0.557166 9.29395 0.560156 9.63812C0.563147 9.98229 0.634651 10.3224 0.770497 10.6386C0.906342 10.9549 1.10381 11.2409 1.35137 11.48L6.53542 16.6641C7.02531 17.1552 7.68628 17.4248 8.36798 17.4248L8.72698 17.3989C9.12427 17.3433 9.50324 17.1963 9.83406 16.9694C10.1649 16.7425 10.4385 16.4419 10.6334 16.0912L17.1135 4.42711C17.2789 4.12961 17.3841 3.80244 17.4231 3.46428C17.4621 3.12611 17.4341 2.78358 17.3407 2.45623C17.2473 2.12889 17.0904 1.82315 16.8788 1.55646C16.6673 1.28978 16.4053 1.06738 16.1078 0.901957Z" fill="#4476F1"/>
    </svg>  
                            </div>
                                <p className="privacy-main-bold">Sensitive data: <p className="privacy-main-text"> Some of the data we collect may be termed sensitive under certain privacy laws (for example, your payment method data). Such data are subject to additional handling precautions.</p></p>     
                        </div>
                    </div>
                </div>
                <div className="privacy-frame-32">
                    <h2 className="privacy-main-header">How we collect your data</h2>
                    <p className="privacy-main-bold">We may collect the data described above in 2 major ways:</p>
                    <div className="grey-tab">
                        <div className="circle-check">
                        <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="https://www.w3.org/2000/svg">
    <path d="M16.1078 0.901957C15.8102 0.736394 15.483 0.631107 15.1447 0.592116C14.8065 0.553125 14.4638 0.581194 14.1364 0.67472C13.809 0.768245 13.5033 0.925392 13.2367 1.13717C12.9701 1.34895 12.7478 1.61121 12.5826 1.90896L7.77052 10.5689L5.01649 7.81489C4.77739 7.56732 4.49137 7.36985 4.17514 7.23401C3.8589 7.09816 3.51877 7.02666 3.17461 7.02367C2.83044 7.02068 2.48912 7.08626 2.17057 7.21659C1.85202 7.34692 1.56262 7.53939 1.31924 7.78276C1.07587 8.02613 0.883407 8.31553 0.753078 8.63408C0.622748 8.95263 0.557166 9.29395 0.560156 9.63812C0.563147 9.98229 0.634651 10.3224 0.770497 10.6386C0.906342 10.9549 1.10381 11.2409 1.35137 11.48L6.53542 16.6641C7.02531 17.1552 7.68628 17.4248 8.36798 17.4248L8.72698 17.3989C9.12427 17.3433 9.50324 17.1963 9.83406 16.9694C10.1649 16.7425 10.4385 16.4419 10.6334 16.0912L17.1135 4.42711C17.2789 4.12961 17.3841 3.80244 17.4231 3.46428C17.4621 3.12611 17.4341 2.78358 17.3407 2.45623C17.2473 2.12889 17.0904 1.82315 16.8788 1.55646C16.6673 1.28978 16.4053 1.06738 16.1078 0.901957Z" fill="#4476F1"/>
    </svg>  
                        </div>
                            <p className="privacy-main-bold">The data you give to us <p className="privacy-main-text"> This may include any data you willingly submit to us, especially via forms on Tylo.ai. (for example, the sign up form, contact us form, etc.).</p></p>     
                    </div>
                    <div className="grey-tab">
                        <div className="circle-check">
                        <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="https://www.w3.org/2000/svg">
    <path d="M16.1078 0.901957C15.8102 0.736394 15.483 0.631107 15.1447 0.592116C14.8065 0.553125 14.4638 0.581194 14.1364 0.67472C13.809 0.768245 13.5033 0.925392 13.2367 1.13717C12.9701 1.34895 12.7478 1.61121 12.5826 1.90896L7.77052 10.5689L5.01649 7.81489C4.77739 7.56732 4.49137 7.36985 4.17514 7.23401C3.8589 7.09816 3.51877 7.02666 3.17461 7.02367C2.83044 7.02068 2.48912 7.08626 2.17057 7.21659C1.85202 7.34692 1.56262 7.53939 1.31924 7.78276C1.07587 8.02613 0.883407 8.31553 0.753078 8.63408C0.622748 8.95263 0.557166 9.29395 0.560156 9.63812C0.563147 9.98229 0.634651 10.3224 0.770497 10.6386C0.906342 10.9549 1.10381 11.2409 1.35137 11.48L6.53542 16.6641C7.02531 17.1552 7.68628 17.4248 8.36798 17.4248L8.72698 17.3989C9.12427 17.3433 9.50324 17.1963 9.83406 16.9694C10.1649 16.7425 10.4385 16.4419 10.6334 16.0912L17.1135 4.42711C17.2789 4.12961 17.3841 3.80244 17.4231 3.46428C17.4621 3.12611 17.4341 2.78358 17.3407 2.45623C17.2473 2.12889 17.0904 1.82315 16.8788 1.55646C16.6673 1.28978 16.4053 1.06738 16.1078 0.901957Z" fill="#4476F1"/>
    </svg>  
                        </div>
                            <p className="privacy-main-bold">The data we collect automatically <p className="privacy-main-text"> The data we collect automatically When you access Tylo.ai, including as a visitor, we use cookies, beacons, pixels, and third-party analytics and tracking tools to gather anonymous data that identifies your behaviours.</p></p>     
                    </div>
                </div>
                <div className="privacy-frame-32">
                    <h2 className="privacy-main-header">Our legal bases for processing your data</h2>
                    <p className="privacy-main-bold">In accordance with the GDPR and other applicable privacy laws, Tylo AI relies on the following legal bases when we process personal data:</p>
                    <div className="grey-container">
                        <div className="grey-row">
                            <div className="grey-sub-container">
                                <div className="circle-check">
                                    <svg width="22" height="26" viewBox="0 0 22 26" fill="none" xmlns="https://www.w3.org/2000/svg">
                                        <path d="M7.56266 14.1459H14.4377M7.56266 18.7292H11.0002M1.8335 3.83335V22.1667C1.8335 22.7745 2.07494 23.3574 2.50471 23.7871C2.93448 24.2169 3.51738 24.4584 4.12516 24.4584H17.8752C18.483 24.4584 19.0658 24.2169 19.4956 23.7871C19.9254 23.3574 20.1668 22.7745 20.1668 22.1667V8.80856C20.1668 8.50327 20.1058 8.20105 19.9873 7.91968C19.8688 7.6383 19.6954 7.38342 19.477 7.17002L14.3895 2.19481C13.9614 1.77619 13.3864 1.54176 12.7877 1.54169H4.12516C3.51738 1.54169 2.93448 1.78313 2.50471 2.2129C2.07494 2.64267 1.8335 3.22557 1.8335 3.83335Z" stroke="#4476F1" stroke-width="2.29167" stroke-linecap="round" stroke-linejoin="round"/>
                                        <path d="M13.292 1.54169V6.12502C13.292 6.73281 13.5334 7.3157 13.9632 7.74547C14.393 8.17524 14.9759 8.41669 15.5837 8.41669H20.167" stroke="#4476F1" stroke-width="2.29167" stroke-linejoin="round"/>
                                    </svg>
                                </div>
                                <p className="privacy-sub-bold">Consent: </p>
                            </div>
                            <p className="privacy-main-text">
                                We mostly rely on consent when we process personal data. This means that you are aware and have given us consent to use the data for the purposes communicated. You have the option to withdraw any given consent at any time. 
                            </p>
                        </div>
                        <div className="grey-row">
                            <div className="grey-sub-container">
                                <div className="circle-check">
                                    <svg width="22" height="26" viewBox="0 0 22 26" fill="none" xmlns="https://www.w3.org/2000/svg">
                                        <path d="M7.56266 14.1459H14.4377M7.56266 18.7292H11.0002M1.8335 3.83335V22.1667C1.8335 22.7745 2.07494 23.3574 2.50471 23.7871C2.93448 24.2169 3.51738 24.4584 4.12516 24.4584H17.8752C18.483 24.4584 19.0658 24.2169 19.4956 23.7871C19.9254 23.3574 20.1668 22.7745 20.1668 22.1667V8.80856C20.1668 8.50327 20.1058 8.20105 19.9873 7.91968C19.8688 7.6383 19.6954 7.38342 19.477 7.17002L14.3895 2.19481C13.9614 1.77619 13.3864 1.54176 12.7877 1.54169H4.12516C3.51738 1.54169 2.93448 1.78313 2.50471 2.2129C2.07494 2.64267 1.8335 3.22557 1.8335 3.83335Z" stroke="#4476F1" stroke-width="2.29167" stroke-linecap="round" stroke-linejoin="round"/>
                                        <path d="M13.292 1.54169V6.12502C13.292 6.73281 13.5334 7.3157 13.9632 7.74547C14.393 8.17524 14.9759 8.41669 15.5837 8.41669H20.167" stroke="#4476F1" stroke-width="2.29167" stroke-linejoin="round"/>
                                    </svg>
                                </div>
                                <p className="privacy-sub-bold">Contract performance:  </p>
                            </div>
                            <p className="privacy-main-text">
                                We may also process your data because we need it to perform a contract with you (for example, we need your inputs on the question box to provide relevant answers).
                            </p>
                        </div>
                        <div className="grey-row">
                            <div className="grey-sub-container">
                                <div className="circle-check">
                                    <svg width="22" height="26" viewBox="0 0 22 26" fill="none" xmlns="https://www.w3.org/2000/svg">
                                        <path d="M7.56266 14.1459H14.4377M7.56266 18.7292H11.0002M1.8335 3.83335V22.1667C1.8335 22.7745 2.07494 23.3574 2.50471 23.7871C2.93448 24.2169 3.51738 24.4584 4.12516 24.4584H17.8752C18.483 24.4584 19.0658 24.2169 19.4956 23.7871C19.9254 23.3574 20.1668 22.7745 20.1668 22.1667V8.80856C20.1668 8.50327 20.1058 8.20105 19.9873 7.91968C19.8688 7.6383 19.6954 7.38342 19.477 7.17002L14.3895 2.19481C13.9614 1.77619 13.3864 1.54176 12.7877 1.54169H4.12516C3.51738 1.54169 2.93448 1.78313 2.50471 2.2129C2.07494 2.64267 1.8335 3.22557 1.8335 3.83335Z" stroke="#4476F1" stroke-width="2.29167" stroke-linecap="round" stroke-linejoin="round"/>
                                        <path d="M13.292 1.54169V6.12502C13.292 6.73281 13.5334 7.3157 13.9632 7.74547C14.393 8.17524 14.9759 8.41669 15.5837 8.41669H20.167" stroke="#4476F1" stroke-width="2.29167" stroke-linejoin="round"/>
                                    </svg>
                                </div>
                                <p className="privacy-sub-bold">Legal obligation:  </p>
                            </div>
                            <p className="privacy-main-text">
                                We may process some of your data because we are required by law to do so (for example, we are required to keep records of transactions).
                            </p>
                        </div>
                        <div className="grey-row">
                            <div className="grey-sub-container">
                                <div className="circle-check">
                                    <svg width="22" height="26" viewBox="0 0 22 26" fill="none" xmlns="https://www.w3.org/2000/svg">
                                        <path d="M7.56266 14.1459H14.4377M7.56266 18.7292H11.0002M1.8335 3.83335V22.1667C1.8335 22.7745 2.07494 23.3574 2.50471 23.7871C2.93448 24.2169 3.51738 24.4584 4.12516 24.4584H17.8752C18.483 24.4584 19.0658 24.2169 19.4956 23.7871C19.9254 23.3574 20.1668 22.7745 20.1668 22.1667V8.80856C20.1668 8.50327 20.1058 8.20105 19.9873 7.91968C19.8688 7.6383 19.6954 7.38342 19.477 7.17002L14.3895 2.19481C13.9614 1.77619 13.3864 1.54176 12.7877 1.54169H4.12516C3.51738 1.54169 2.93448 1.78313 2.50471 2.2129C2.07494 2.64267 1.8335 3.22557 1.8335 3.83335Z" stroke="#4476F1" stroke-width="2.29167" stroke-linecap="round" stroke-linejoin="round"/>
                                        <path d="M13.292 1.54169V6.12502C13.292 6.73281 13.5334 7.3157 13.9632 7.74547C14.393 8.17524 14.9759 8.41669 15.5837 8.41669H20.167" stroke="#4476F1" stroke-width="2.29167" stroke-linejoin="round"/>
                                    </svg>
                                </div>
                                <p className="privacy-sub-bold">Legitimate interest: </p>
                            </div>
                            <p className="privacy-main-text">
                                We may process personal data based on our legitimate interests, including where it helps us to detect violations, make Tylo.ai secure, and improve and enhance its functionality.
                            </p>
                        </div>
                    </div>
                </div>
                <div className="privacy-frame-32">
                    <h2 className="privacy-main-header">How we Use your data</h2>
                    <p className="privacy-main-bold">In summary, we collect your data to provide and improve Tylo.ai, communicate with you and comply with applicable laws. Specifically, we use your data for the following purposes:</p>
                    <div className="grey-container">
                        <div className="grey-sub-full">
                            <div className="circle-check">01</div>
                                <p className="privacy-main-text">To provide you with an account, a profile, and provide you access to features on Tylo.ai</p>     
                        </div>
                        <div className="grey-sub-full">
                            <div className="circle-check">02</div>
                                <p className="privacy-main-text">To notify you of stuff related to your account and use of Tylo.ai</p>     
                        </div>
                        <div className="grey-sub-full">
                            <div className="circle-check">03</div>
                                <p className="privacy-main-text">To detect and monitor violations of and enforce our Terms of Use</p>     
                        </div>
                    </div>
                    <div className="grey-container">
                        <div className="grey-sub-full">
                            <div className="circle-check">04</div>
                                <p className="privacy-main-text">To verify your account and identify you</p>     
                        </div>
                        <div className="grey-sub-full">
                            <div className="circle-check">05</div>
                                <p className="privacy-main-text">To notify you of promotions, bonuses and marketing messages if you consent</p>     
                        </div>
                        <div className="grey-sub-full">
                            <div className="circle-check">06</div>
                                <p className="privacy-main-text">To learn about your behaviours to further personalize Tylo.ai to you</p>     
                        </div>
                    </div>
                    <div className="grey-container">
                        <div className="grey-sub-full">
                            <div className="circle-check">07</div>
                                <p className="privacy-main-text">To secure your account from unauthorized access</p>     
                        </div>
                        <div className="grey-sub-full">
                            <div className="circle-check">08</div>
                                <p className="privacy-main-text">To process your subscription payments and maintain records</p>     
                        </div>
                        <div className="grey-sub-full">
                            <div className="circle-check">09</div>
                                <p className="privacy-main-text">To gather analytics and improve Tylo.ai.</p>     
                        </div>
                    </div>
                    <div className="grey-container">
                        <div className="grey-sub-full">
                            <div className="circle-check">10</div>
                                <p className="privacy-main-text">To communicate with you, including responding to your inquiries and updating you about any news or events about Tylo.ai</p>     
                        </div>
                        <div className="grey-sub-full">
                            <div className="circle-check">11</div>
                                <p className="privacy-main-text">To comply with applicable laws and regulations</p>     
                        </div>
                    </div>
                </div>
                <div className="privacy-frame-32">
                    <h2 className="privacy-main-header">How we may disclose your data</h2>
                    <p className="privacy-main-bold">We do not disclose your data except as described below:</p>
                    <div className="grey-container">
                        
                            <div className="grey-row">
                                <div className="grey-sub-container">
                                    <div className="circle-check">
                                    <svg width="28" height="24" viewBox="0 0 28 24" fill="none" xmlns="https://www.w3.org/2000/svg">
  <path d="M16.4968 10.4121L13.9998 9.27275L16.4968 8.13336L17.6362 5.63639L18.7756 8.13336L21.2726 9.27275L18.7756 10.4121L17.6362 12.9091L16.4968 10.4121ZM4.30287 15.3334L5.44226 12.8364L7.93923 11.697L5.44226 10.5576L4.30287 8.06063L3.16347 10.5576L0.666504 11.697L3.16347 12.8364L4.30287 15.3334ZM9.75741 9.27275L11.0786 6.35154L13.9998 5.03033L11.0786 3.70911L9.75741 0.787903L8.4362 3.70911L5.51499 5.03033L8.4362 6.35154L9.75741 9.27275ZM4.90893 23.2121L12.1817 15.9273L17.0301 20.7758L27.3332 9.1879L25.6241 7.47881L17.0301 17.1394L12.1817 12.2909L3.09075 21.394L4.90893 23.2121Z" fill="#4476F1"/>
</svg>
                                    </div>
                                    <p className="privacy-sub-bold">Third-party service providers</p>
                                </div>
                                <p className="privacy-main-text">
                                We may use third parties to provide some of the Tylo.ai functionality, including payment processing, website hosting, data storage and marketing. These parties may access personal data to perform their services for us. However, they are obligated (through business agreements) to only use the data for Tylo.ai purposes.
                                </p>
                            </div>
                    
                        
                            <div className="grey-row">
                                <div className="grey-sub-container">
                                    <div className="circle-check">
                                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="https://www.w3.org/2000/svg">
  <path d="M10.9165 3.33331V9.83331C10.9165 11.0304 8.73359 12 6.0415 12C3.34942 12 1.1665 11.0304 1.1665 9.83331V3.33331" stroke="#4476F1" stroke-width="1.66667" stroke-linecap="round" stroke-linejoin="round"/>
  <path d="M10.9165 6.58335C10.9165 7.78044 8.73359 8.75002 6.0415 8.75002C3.34942 8.75002 1.1665 7.78044 1.1665 6.58335M16.3332 2.25002H19.5832C20.1578 2.25002 20.7089 2.47829 21.1152 2.88462C21.5216 3.29095 21.7498 3.84205 21.7498 4.41669V7.66669M7.6665 21.75H4.4165C3.84187 21.75 3.29077 21.5217 2.88444 21.1154C2.47811 20.7091 2.24984 20.158 2.24984 19.5834V16.3334M10.9165 3.33335C10.9165 4.53044 8.73359 5.50002 6.0415 5.50002C3.34942 5.50002 1.1665 4.53044 1.1665 3.33335C1.1665 2.13627 3.34942 1.16669 6.0415 1.16669C8.73359 1.16669 10.9165 2.13627 10.9165 3.33335Z" stroke="#4476F1" stroke-width="1.66667" stroke-linecap="round" stroke-linejoin="round"/>
  <path d="M17.9583 17.4167C19.4541 17.4167 20.6667 16.2041 20.6667 14.7083C20.6667 13.2126 19.4541 12 17.9583 12C16.4626 12 15.25 13.2126 15.25 14.7083C15.25 16.2041 16.4626 17.4167 17.9583 17.4167Z" stroke="#4476F1" stroke-width="1.66667" stroke-linecap="round" stroke-linejoin="round"/>
  <path d="M22.833 22.8334H13.083C13.083 22.1932 13.2091 21.5593 13.4541 20.9678C13.6991 20.3763 14.0582 19.8389 14.5109 19.3862C14.9635 18.9335 15.501 18.5745 16.0924 18.3295C16.6839 18.0845 17.3178 17.9584 17.958 17.9584C18.5982 17.9584 19.2321 18.0845 19.8236 18.3295C20.4151 18.5745 20.9525 18.9335 21.4052 19.3862C21.8578 19.8389 22.2169 20.3763 22.4619 20.9678C22.7069 21.5593 22.833 22.1932 22.833 22.8334Z" stroke="#4476F1" stroke-width="1.66667" stroke-linecap="round" stroke-linejoin="round"/>
</svg>
                                    </div>
                                    <p className="privacy-sub-bold">At your request</p>
                                </div>
                                <p className="privacy-main-text">
                                We may also transfer your data based on your request or consent. For example, if you request to use the share and review feature that allows you to share content with specific people or groups, those people or groups will see your data.
                                </p>
                            </div>
                            
                    </div>
                    <div className="grey-container">
                        
                            <div className="grey-row">
                                <div className="grey-sub-container">
                                    <div className="circle-check">
                                    <svg width="22" height="26" viewBox="0 0 22 26" fill="none" xmlns="https://www.w3.org/2000/svg">
  <path d="M7.56266 14.1459H14.4377M7.56266 18.7292H11.0002M1.8335 3.83335V22.1667C1.8335 22.7745 2.07494 23.3574 2.50471 23.7871C2.93448 24.2169 3.51738 24.4584 4.12516 24.4584H17.8752C18.483 24.4584 19.0658 24.2169 19.4956 23.7871C19.9254 23.3574 20.1668 22.7745 20.1668 22.1667V8.80856C20.1668 8.50327 20.1058 8.20105 19.9873 7.91968C19.8688 7.6383 19.6954 7.38342 19.477 7.17002L14.3895 2.19481C13.9614 1.77619 13.3864 1.54176 12.7877 1.54169H4.12516C3.51738 1.54169 2.93448 1.78313 2.50471 2.2129C2.07494 2.64267 1.8335 3.22557 1.8335 3.83335Z" stroke="#4476F1" stroke-width="2.29167" stroke-linecap="round" stroke-linejoin="round"/>
  <path d="M13.292 1.54175V6.12508C13.292 6.73287 13.5334 7.31576 13.9632 7.74553C14.393 8.17531 14.9759 8.41675 15.5837 8.41675H20.167" stroke="#4476F1" stroke-width="2.29167" stroke-linejoin="round"/>
</svg>
                                    </div>
                                    <p className="privacy-sub-bold">Legal requirements</p>
                                </div>
                                <p className="privacy-main-text">
                                We may disclose personal data in good faith belief if it is necessary to (i) comply with a legal obligation; (ii) protect and defend our rights and intellectual property and those of other users or third parties; (iii) prevent or investigate possible wrongdoing in connection with Tylo.ai; (iv) protect the personal safety of Tylo.ai users or the public; or (v) protect against legal liability.
                                </p>
                            </div>
                    
                        
                            <div className="grey-row">
                                <div className="grey-sub-container">
                                    <div className="circle-check">
                                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="https://www.w3.org/2000/svg">
  <path d="M18.5106 22.4166V21.1146M18.5106 15.9062V14.6041M5.48975 1.58331V22.4166M5.48975 22.4166L1.5835 18.5104M5.48975 22.4166L9.396 18.5104M18.5106 9.39581V1.58331M18.5106 1.58331L14.6043 5.48956M18.5106 1.58331L22.4168 5.48956" stroke="#4476F1" stroke-width="1.95312" stroke-linecap="round" stroke-linejoin="round"/>
</svg>
                                    </div>
                                    <p className="privacy-sub-bold">Business transfers</p>
                                </div>
                                <p className="privacy-main-text">
                                If we are involved in a merger, consolidation, acquisition or asset sale, or we are subject to bankruptcy, we may disclose your data to the companies involved. We will provide notice before your data is transferred and becomes subject to a different privacy policy.
                                </p>
                            </div>
                            
                    </div>
                    <div className="grey-row">
                        <div className="grey-sub-container">
                            <div className="circle-check">
                            <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="https://www.w3.org/2000/svg">
  <path d="M0.916504 13C0.916504 7.30394 0.916504 4.4559 2.6855 2.68569C4.45692 0.916687 7.30375 0.916687 12.9998 0.916687C18.6959 0.916687 21.544 0.916687 23.313 2.68569C25.0832 4.4571 25.0832 7.30394 25.0832 13C25.0832 18.6961 25.0832 21.5441 23.313 23.3131C21.5452 25.0834 18.6959 25.0834 12.9998 25.0834C7.30375 25.0834 4.45571 25.0834 2.6855 23.3131C0.916504 21.5454 0.916504 18.6961 0.916504 13Z" stroke="#4476F1" stroke-width="1.8125"/>
  <path d="M6.95801 15.4167L9.72872 12.646C9.95531 12.4195 10.2626 12.2922 10.583 12.2922C10.9034 12.2922 11.2107 12.4195 11.4373 12.646L13.3537 14.5624C13.5803 14.7889 13.8876 14.9162 14.208 14.9162C14.5284 14.9162 14.8357 14.7889 15.0623 14.5624L19.0413 10.5834M19.0413 10.5834V13.6042M19.0413 10.5834H16.0205" stroke="#4476F1" stroke-width="1.8125" stroke-linecap="round" stroke-linejoin="round"/>
</svg>
                            </div>
                            <p className="privacy-sub-bold">Analytics partners</p>
                        </div>
                        <p className="privacy-main-text">
                        We may use tools from third parties to gather analytics and obtain insights about how users use Tylo.ai, we then use the data for personalization and improvement. These parties may have anonymous data about users’ devices and other behavioural data described in the data collection section of this Privacy Policy (for example, we may use Google Analytics, from Google, or any other third-party analytics tool). You can opt out from Google Analytics tracking by downloading the Google Analytics Opt-out  Browser Add-on.
                        </p>
                    </div>
                </div>
                <div className="privacy-frame-32">
                    <h2 className="privacy-main-header">How we secure your data</h2>
                    <p className="privacy-main-text">
                    We are committed to ensuring the security of your data. We implement various measures to safeguard the confidentiality, integrity and availability of your data. We use Secure Socket Layer (SSL) Encryption to ensure all data transmitted between your device and Tylo.ai is secured and private to external parties. We ensure third parties we use sign Non-Disclosure Agreements to prevent them from using your data for anything other than our offerings. We limit access to your data on a need-to-know basis, including with our employees.
                    </p>
                    <p className="privacy-main-text">
                    Furthermore, we may use trusted payment processors to process your subscription. If you provide any payment method data, it is directly collected by our processors and used to process your payments. We only collect the data we need to provide Tylo.ai.   
                    </p>
                    <p className="privacy-main-text">
                    However, despite our security measures, it may not be possible to prevent all unauthorised access attempts, as no data transmission or storage is entirely foolproof. Therefore, while we strive to protect your data, we cannot guarantee its absolute security.
                    </p>
                </div>
                <div className="privacy-frame-32">
                    <h2 className="privacy-main-header">How long we retain your data</h2>
                    <p className="privacy-main-text">
                    Our retention period depends on the type of data and its uses. However, we generally retain data until its purpose of collection is achieved. Once you request the deletion of your data, we take steps to delete it within 30 days. For example, we retain your account data until your account is closed. Your payment method may be retained by our payment processing partner until you decide to request the deletion. Information about your device and behaviour on Tylo.ai is usually retained and deleted automatically after 18 months.
                    </p>
                </div>
                <div className="privacy-frame-32">
                    <h2 className="privacy-main-header">International transfer of personal data</h2>
                    <p className="privacy-main-text">
                    As a Data Controller, we are registered in and operate in the United Kingdom, and we process personal data in accordance with the Data Protection Act 2018 and GDPR. However, we may use third parties in other locations and share data with them. This means that they may process the data in accordance with laws different from the Data Protection Act 2018 and GDPR. By using Tylo.ai, you consent to the international transfer of your data. However, we take all reasonable steps to comply with the requirements of regulations regarding the international transfer of data. We ensure the third parties are bound by confidentiality agreements, they are reputable, and where relevant, we use Standard Contractual Clauses provided by the European Commission.
                    </p>
                </div>
                <div className="privacy-frame-32">
                    <h2 className="privacy-main-header">Cookies and similar technologies</h2>
                    <p className="privacy-main-bold">Tylo AI uses cookies, web beacons, pixels and similar tracking technologies to enhance your experience, gather behavioural data, secure Tylo.ai and enable certain functionalities. Cookies are tiny, harmless data that is attached to your browser when you access a website.</p>
                    
                        <div className="grey-tab-full">
                            <div className="circle-check">
                            <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="https://www.w3.org/2000/svg">
    <path d="M16.1078 0.901957C15.8102 0.736394 15.483 0.631107 15.1447 0.592116C14.8065 0.553125 14.4638 0.581194 14.1364 0.67472C13.809 0.768245 13.5033 0.925392 13.2367 1.13717C12.9701 1.34895 12.7478 1.61121 12.5826 1.90896L7.77052 10.5689L5.01649 7.81489C4.77739 7.56732 4.49137 7.36985 4.17514 7.23401C3.8589 7.09816 3.51877 7.02666 3.17461 7.02367C2.83044 7.02068 2.48912 7.08626 2.17057 7.21659C1.85202 7.34692 1.56262 7.53939 1.31924 7.78276C1.07587 8.02613 0.883407 8.31553 0.753078 8.63408C0.622748 8.95263 0.557166 9.29395 0.560156 9.63812C0.563147 9.98229 0.634651 10.3224 0.770497 10.6386C0.906342 10.9549 1.10381 11.2409 1.35137 11.48L6.53542 16.6641C7.02531 17.1552 7.68628 17.4248 8.36798 17.4248L8.72698 17.3989C9.12427 17.3433 9.50324 17.1963 9.83406 16.9694C10.1649 16.7425 10.4385 16.4419 10.6334 16.0912L17.1135 4.42711C17.2789 4.12961 17.3841 3.80244 17.4231 3.46428C17.4621 3.12611 17.4341 2.78358 17.3407 2.45623C17.2473 2.12889 17.0904 1.82315 16.8788 1.55646C16.6673 1.28978 16.4053 1.06738 16.1078 0.901957Z" fill="#4476F1"/>
    </svg>  
                            </div>
                                <p className="privacy-main-bold">Cookie consent banner <p className="privacy-main-text"> Upon visiting Tylo.ai, you might encounter a cookie consent banner. This banner provides you with the option to accept or reject the use of cookies on Tylo.ai. By accepting our use of cookies on the cookie banner, you agree to our use of all cookies mentioned in this section.</p></p>     
                        </div>

                        <div className="grey-tab-full">
                            <div className="circle-check">
                            <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="https://www.w3.org/2000/svg">
    <path d="M16.1078 0.901957C15.8102 0.736394 15.483 0.631107 15.1447 0.592116C14.8065 0.553125 14.4638 0.581194 14.1364 0.67472C13.809 0.768245 13.5033 0.925392 13.2367 1.13717C12.9701 1.34895 12.7478 1.61121 12.5826 1.90896L7.77052 10.5689L5.01649 7.81489C4.77739 7.56732 4.49137 7.36985 4.17514 7.23401C3.8589 7.09816 3.51877 7.02666 3.17461 7.02367C2.83044 7.02068 2.48912 7.08626 2.17057 7.21659C1.85202 7.34692 1.56262 7.53939 1.31924 7.78276C1.07587 8.02613 0.883407 8.31553 0.753078 8.63408C0.622748 8.95263 0.557166 9.29395 0.560156 9.63812C0.563147 9.98229 0.634651 10.3224 0.770497 10.6386C0.906342 10.9549 1.10381 11.2409 1.35137 11.48L6.53542 16.6641C7.02531 17.1552 7.68628 17.4248 8.36798 17.4248L8.72698 17.3989C9.12427 17.3433 9.50324 17.1963 9.83406 16.9694C10.1649 16.7425 10.4385 16.4419 10.6334 16.0912L17.1135 4.42711C17.2789 4.12961 17.3841 3.80244 17.4231 3.46428C17.4621 3.12611 17.4341 2.78358 17.3407 2.45623C17.2473 2.12889 17.0904 1.82315 16.8788 1.55646C16.6673 1.28978 16.4053 1.06738 16.1078 0.901957Z" fill="#4476F1"/>
    </svg>  
                            </div>
                                <p className="privacy-main-bold">Types of cookies used <p className="privacy-main-text"> Session cookies: Session cookies are temporary and are used to maintain session information for the user during their visit to Tylo.ai. These cookies are essential for the proper functioning of Tylo.ai and ensure a seamless user experience. They are automatically deleted from your device when you close your browser.</p></p>     
                        </div>

                        <div className="grey-tab-full">
                            <div className="circle-check">
                            <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="https://www.w3.org/2000/svg">
    <path d="M16.1078 0.901957C15.8102 0.736394 15.483 0.631107 15.1447 0.592116C14.8065 0.553125 14.4638 0.581194 14.1364 0.67472C13.809 0.768245 13.5033 0.925392 13.2367 1.13717C12.9701 1.34895 12.7478 1.61121 12.5826 1.90896L7.77052 10.5689L5.01649 7.81489C4.77739 7.56732 4.49137 7.36985 4.17514 7.23401C3.8589 7.09816 3.51877 7.02666 3.17461 7.02367C2.83044 7.02068 2.48912 7.08626 2.17057 7.21659C1.85202 7.34692 1.56262 7.53939 1.31924 7.78276C1.07587 8.02613 0.883407 8.31553 0.753078 8.63408C0.622748 8.95263 0.557166 9.29395 0.560156 9.63812C0.563147 9.98229 0.634651 10.3224 0.770497 10.6386C0.906342 10.9549 1.10381 11.2409 1.35137 11.48L6.53542 16.6641C7.02531 17.1552 7.68628 17.4248 8.36798 17.4248L8.72698 17.3989C9.12427 17.3433 9.50324 17.1963 9.83406 16.9694C10.1649 16.7425 10.4385 16.4419 10.6334 16.0912L17.1135 4.42711C17.2789 4.12961 17.3841 3.80244 17.4231 3.46428C17.4621 3.12611 17.4341 2.78358 17.3407 2.45623C17.2473 2.12889 17.0904 1.82315 16.8788 1.55646C16.6673 1.28978 16.4053 1.06738 16.1078 0.901957Z" fill="#4476F1"/>
    </svg>  
                            </div>
                                <p className="privacy-main-bold">Analytical cookies: <p className="privacy-main-text"> Analytical cookies collect data about how visitors use Tylo.ai and help analyze website performance and user behaviour. These cookies provide us with valuable insights into how users interact with Tylo.ai. The information collected is used to improve Tylo.ai’s functionality, content, and overall user experience.</p></p>     
                        </div>

                        <div className="grey-tab-full">
                            <div className="circle-check">
                            <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="https://www.w3.org/2000/svg">
    <path d="M16.1078 0.901957C15.8102 0.736394 15.483 0.631107 15.1447 0.592116C14.8065 0.553125 14.4638 0.581194 14.1364 0.67472C13.809 0.768245 13.5033 0.925392 13.2367 1.13717C12.9701 1.34895 12.7478 1.61121 12.5826 1.90896L7.77052 10.5689L5.01649 7.81489C4.77739 7.56732 4.49137 7.36985 4.17514 7.23401C3.8589 7.09816 3.51877 7.02666 3.17461 7.02367C2.83044 7.02068 2.48912 7.08626 2.17057 7.21659C1.85202 7.34692 1.56262 7.53939 1.31924 7.78276C1.07587 8.02613 0.883407 8.31553 0.753078 8.63408C0.622748 8.95263 0.557166 9.29395 0.560156 9.63812C0.563147 9.98229 0.634651 10.3224 0.770497 10.6386C0.906342 10.9549 1.10381 11.2409 1.35137 11.48L6.53542 16.6641C7.02531 17.1552 7.68628 17.4248 8.36798 17.4248L8.72698 17.3989C9.12427 17.3433 9.50324 17.1963 9.83406 16.9694C10.1649 16.7425 10.4385 16.4419 10.6334 16.0912L17.1135 4.42711C17.2789 4.12961 17.3841 3.80244 17.4231 3.46428C17.4621 3.12611 17.4341 2.78358 17.3407 2.45623C17.2473 2.12889 17.0904 1.82315 16.8788 1.55646C16.6673 1.28978 16.4053 1.06738 16.1078 0.901957Z" fill="#4476F1"/>
    </svg>  
                            </div>
                                <p className="privacy-main-bold">Functional cookies: <p className="privacy-main-text"> Functional cookies enable certain functionalities on Tylo.ai, such as remembering user inputs. These cookies enhance Tylo.ai’s usability by remembering your preferences and choices, contributing to a more personalised and convenient browsing experience.</p></p>     
                        </div>

                        <div className="grey-tab-full">
                            <div className="circle-check">
                            <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="https://www.w3.org/2000/svg">
    <path d="M16.1078 0.901957C15.8102 0.736394 15.483 0.631107 15.1447 0.592116C14.8065 0.553125 14.4638 0.581194 14.1364 0.67472C13.809 0.768245 13.5033 0.925392 13.2367 1.13717C12.9701 1.34895 12.7478 1.61121 12.5826 1.90896L7.77052 10.5689L5.01649 7.81489C4.77739 7.56732 4.49137 7.36985 4.17514 7.23401C3.8589 7.09816 3.51877 7.02666 3.17461 7.02367C2.83044 7.02068 2.48912 7.08626 2.17057 7.21659C1.85202 7.34692 1.56262 7.53939 1.31924 7.78276C1.07587 8.02613 0.883407 8.31553 0.753078 8.63408C0.622748 8.95263 0.557166 9.29395 0.560156 9.63812C0.563147 9.98229 0.634651 10.3224 0.770497 10.6386C0.906342 10.9549 1.10381 11.2409 1.35137 11.48L6.53542 16.6641C7.02531 17.1552 7.68628 17.4248 8.36798 17.4248L8.72698 17.3989C9.12427 17.3433 9.50324 17.1963 9.83406 16.9694C10.1649 16.7425 10.4385 16.4419 10.6334 16.0912L17.1135 4.42711C17.2789 4.12961 17.3841 3.80244 17.4231 3.46428C17.4621 3.12611 17.4341 2.78358 17.3407 2.45623C17.2473 2.12889 17.0904 1.82315 16.8788 1.55646C16.6673 1.28978 16.4053 1.06738 16.1078 0.901957Z" fill="#4476F1"/>
    </svg>  
                            </div>
                                <p className="privacy-main-bold">Third-party cookies <p className="privacy-main-text"> These cookies are set by our third parties that perform certain tasks on our behalf. For example, Google, Microsoft Azure and Stripe may embed cookies on Tylo.ai to enable them to provide their services for us.</p></p>     
                        </div>

                        <div className="grey-tab-full">
                            <div className="circle-check">
                            <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="https://www.w3.org/2000/svg">
    <path d="M16.1078 0.901957C15.8102 0.736394 15.483 0.631107 15.1447 0.592116C14.8065 0.553125 14.4638 0.581194 14.1364 0.67472C13.809 0.768245 13.5033 0.925392 13.2367 1.13717C12.9701 1.34895 12.7478 1.61121 12.5826 1.90896L7.77052 10.5689L5.01649 7.81489C4.77739 7.56732 4.49137 7.36985 4.17514 7.23401C3.8589 7.09816 3.51877 7.02666 3.17461 7.02367C2.83044 7.02068 2.48912 7.08626 2.17057 7.21659C1.85202 7.34692 1.56262 7.53939 1.31924 7.78276C1.07587 8.02613 0.883407 8.31553 0.753078 8.63408C0.622748 8.95263 0.557166 9.29395 0.560156 9.63812C0.563147 9.98229 0.634651 10.3224 0.770497 10.6386C0.906342 10.9549 1.10381 11.2409 1.35137 11.48L6.53542 16.6641C7.02531 17.1552 7.68628 17.4248 8.36798 17.4248L8.72698 17.3989C9.12427 17.3433 9.50324 17.1963 9.83406 16.9694C10.1649 16.7425 10.4385 16.4419 10.6334 16.0912L17.1135 4.42711C17.2789 4.12961 17.3841 3.80244 17.4231 3.46428C17.4621 3.12611 17.4341 2.78358 17.3407 2.45623C17.2473 2.12889 17.0904 1.82315 16.8788 1.55646C16.6673 1.28978 16.4053 1.06738 16.1078 0.901957Z" fill="#4476F1"/>
    </svg>  
                            </div>
                                <p className="privacy-main-bold">Managing cookies <p className="privacy-main-text"> If you do not want your online activities monitored via cookies, you can manage your preferences on the cookie consent banner or simply go to your browser privacy settings section. Please refer to your browser's help documentation for information on how to disable or delete cookies. Keep in mind that disabling cookies may limit your smooth experience on Tylo.ai.</p></p>     
                        </div>
                    
                </div>
                <div className="privacy-frame-32">
                    <h2 className="privacy-main-header">Our marketing practices</h2>
                    <p className="privacy-main-bold">Tylo AI uses cookies, web beacons, pixels and similar tracking technologies to enhance your experience, gather behavioural data, secure Tylo.ai and enable certain functionalities. Cookies are tiny, harmless data that is attached to your browser when you access a website.</p>
                    <div className="grey-container">
                        
                            <div className="grey-row">
                                <div className="grey-sub-container">
                                    <div className="circle-check">
                                    <svg width="28" height="24" viewBox="0 0 28 24" fill="none" xmlns="https://www.w3.org/2000/svg">
  <path d="M16.4968 10.4121L13.9998 9.27275L16.4968 8.13336L17.6362 5.63639L18.7756 8.13336L21.2726 9.27275L18.7756 10.4121L17.6362 12.9091L16.4968 10.4121ZM4.30287 15.3334L5.44226 12.8364L7.93923 11.697L5.44226 10.5576L4.30287 8.06063L3.16347 10.5576L0.666504 11.697L3.16347 12.8364L4.30287 15.3334ZM9.75741 9.27275L11.0786 6.35154L13.9998 5.03033L11.0786 3.70911L9.75741 0.787903L8.4362 3.70911L5.51499 5.03033L8.4362 6.35154L9.75741 9.27275ZM4.90893 23.2121L12.1817 15.9273L17.0301 20.7758L27.3332 9.1879L25.6241 7.47881L17.0301 17.1394L12.1817 12.2909L3.09075 21.394L4.90893 23.2121Z" fill="#4476F1"/>
</svg>
                                    </div>
                                    <p className="privacy-sub-bold">Email marketing</p>
                                </div>
                                <p className="privacy-main-text">
                                We engage in email marketing where we send occasional marketing emails about offers, promotions and news we think may interest you. We send marketing emails based on your consent (i.e., when you submit your email address in the opt-in form), and we also provide an opt-out means if you wish to withdraw your consent. You can use the “unsubscribe” link at the bottom of any email from us to opt out. Please be aware that marketing emails do not relate to administrative emails, such as updates and notices about your account.
                                </p>
                            </div>
                    
                        
                            <div className="grey-row">
                                <div className="grey-sub-container">
                                    <div className="circle-check">
                                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="https://www.w3.org/2000/svg">
  <path d="M10.9165 3.33331V9.83331C10.9165 11.0304 8.73359 12 6.0415 12C3.34942 12 1.1665 11.0304 1.1665 9.83331V3.33331" stroke="#4476F1" stroke-width="1.66667" stroke-linecap="round" stroke-linejoin="round"/>
  <path d="M10.9165 6.58335C10.9165 7.78044 8.73359 8.75002 6.0415 8.75002C3.34942 8.75002 1.1665 7.78044 1.1665 6.58335M16.3332 2.25002H19.5832C20.1578 2.25002 20.7089 2.47829 21.1152 2.88462C21.5216 3.29095 21.7498 3.84205 21.7498 4.41669V7.66669M7.6665 21.75H4.4165C3.84187 21.75 3.29077 21.5217 2.88444 21.1154C2.47811 20.7091 2.24984 20.158 2.24984 19.5834V16.3334M10.9165 3.33335C10.9165 4.53044 8.73359 5.50002 6.0415 5.50002C3.34942 5.50002 1.1665 4.53044 1.1665 3.33335C1.1665 2.13627 3.34942 1.16669 6.0415 1.16669C8.73359 1.16669 10.9165 2.13627 10.9165 3.33335Z" stroke="#4476F1" stroke-width="1.66667" stroke-linecap="round" stroke-linejoin="round"/>
  <path d="M17.9583 17.4167C19.4541 17.4167 20.6667 16.2041 20.6667 14.7083C20.6667 13.2126 19.4541 12 17.9583 12C16.4626 12 15.25 13.2126 15.25 14.7083C15.25 16.2041 16.4626 17.4167 17.9583 17.4167Z" stroke="#4476F1" stroke-width="1.66667" stroke-linecap="round" stroke-linejoin="round"/>
  <path d="M22.833 22.8334H13.083C13.083 22.1932 13.2091 21.5593 13.4541 20.9678C13.6991 20.3763 14.0582 19.8389 14.5109 19.3862C14.9635 18.9335 15.501 18.5745 16.0924 18.3295C16.6839 18.0845 17.3178 17.9584 17.958 17.9584C18.5982 17.9584 19.2321 18.0845 19.8236 18.3295C20.4151 18.5745 20.9525 18.9335 21.4052 19.3862C21.8578 19.8389 22.2169 20.3763 22.4619 20.9678C22.7069 21.5593 22.833 22.1932 22.833 22.8334Z" stroke="#4476F1" stroke-width="1.66667" stroke-linecap="round" stroke-linejoin="round"/>
</svg>
                                    </div>
                                    <p className="privacy-sub-bold">Interest-based advertising</p>
                                </div>
                                <p className="privacy-main-text">
                                We may work with ad networks and network advertisers to provide us with traffic analytics and advertise Tylo.ai on their platforms and across other websites. We may allow these third parties to collect data about your behaviours on Tylo.ai. We may also share the usage and behavioural data we have anonymously gathered with them. Then they may gather the same data about you on other platforms you visit and use it to show you interest-based adverts.
                                </p>
                            </div>                    
                    </div>
                    <div className="grey-row">
                                <div className="grey-sub-container">
                                    <p className="privacy-sub-bold">You can opt out of interest-based advertising by doing any of the following:</p>
                                </div>
                                <div className="grey-sub-container">
                                    <div className="circle-check">
                                    <svg width="18" height="14" viewBox="0 0 18 14" fill="none" xmlns="https://www.w3.org/2000/svg">
  <path d="M2.06683 13.9336C1.59016 13.9336 1.18225 13.764 0.843096 13.4248C0.503941 13.0857 0.334074 12.6775 0.333496 12.2002V1.80023C0.333496 1.32356 0.503363 0.91565 0.843096 0.576494C1.18283 0.237339 1.59074 0.0674723 2.06683 0.0668945H15.9335C16.4102 0.0668945 16.8184 0.236761 17.1581 0.576494C17.4978 0.916228 17.6674 1.32414 17.6668 1.80023V12.2002C17.6668 12.6769 17.4972 13.0851 17.1581 13.4248C16.8189 13.7646 16.4107 13.9341 15.9335 13.9336H2.06683ZM3.80016 11.3336H14.2002C14.4457 11.3336 14.6517 11.2504 14.8181 11.084C14.9845 10.9176 15.0674 10.7119 15.0668 10.4669C15.0668 10.2213 14.9836 10.0156 14.8172 9.84983C14.6508 9.684 14.4451 9.6008 14.2002 9.60023H3.80016C3.55461 9.60023 3.34892 9.68343 3.1831 9.84983C3.01727 10.0162 2.93407 10.2219 2.9335 10.4669C2.9335 10.7124 3.0167 10.9184 3.1831 11.0848C3.3495 11.2512 3.55518 11.3341 3.80016 11.3336ZM3.80016 7.86689H14.2002C14.4457 7.86689 14.6517 7.78369 14.8181 7.61729C14.9845 7.45089 15.0674 7.2452 15.0668 7.00023C15.0668 6.75467 14.9836 6.54898 14.8172 6.38316C14.6508 6.21734 14.4451 6.13414 14.2002 6.13356H3.80016C3.55461 6.13356 3.34892 6.21676 3.1831 6.38316C3.01727 6.54956 2.93407 6.75525 2.9335 7.00023C2.9335 7.24578 3.0167 7.45176 3.1831 7.61816C3.3495 7.78456 3.55518 7.86747 3.80016 7.86689ZM3.80016 4.40023H10.7335C10.979 4.40023 11.185 4.31703 11.3514 4.15063C11.5178 3.98423 11.6007 3.77854 11.6002 3.53356C11.6002 3.28801 11.517 3.08232 11.3506 2.91649C11.1842 2.75067 10.9785 2.66747 10.7335 2.66689H3.80016C3.55461 2.66689 3.34892 2.75009 3.1831 2.91649C3.01727 3.08289 2.93407 3.28858 2.9335 3.53356C2.9335 3.77912 3.0167 3.98509 3.1831 4.15149C3.3495 4.31789 3.55518 4.4008 3.80016 4.40023Z" fill="#4476F1"/>
</svg>
                                    </div>
                                    <p className="privacy-main-bold">Ad choices tools: <p className="privacy-main-text"> Many of the ad network companies belong to self-regulatory programs, such as the Digital Advertising Alliance (DAA), Network Advertising Initiative (NAI), and European Interactive Digital Advertising Alliance (EDAA). Click on the respective links to learn more, including how to opt out.</p></p>
                                </div>
                                <div className="grey-sub-container">
                                    <div className="circle-check">
                                    <svg width="18" height="14" viewBox="0 0 18 14" fill="none" xmlns="https://www.w3.org/2000/svg">
  <path d="M2.06683 13.9336C1.59016 13.9336 1.18225 13.764 0.843096 13.4248C0.503941 13.0857 0.334074 12.6775 0.333496 12.2002V1.80023C0.333496 1.32356 0.503363 0.91565 0.843096 0.576494C1.18283 0.237339 1.59074 0.0674723 2.06683 0.0668945H15.9335C16.4102 0.0668945 16.8184 0.236761 17.1581 0.576494C17.4978 0.916228 17.6674 1.32414 17.6668 1.80023V12.2002C17.6668 12.6769 17.4972 13.0851 17.1581 13.4248C16.8189 13.7646 16.4107 13.9341 15.9335 13.9336H2.06683ZM3.80016 11.3336H14.2002C14.4457 11.3336 14.6517 11.2504 14.8181 11.084C14.9845 10.9176 15.0674 10.7119 15.0668 10.4669C15.0668 10.2213 14.9836 10.0156 14.8172 9.84983C14.6508 9.684 14.4451 9.6008 14.2002 9.60023H3.80016C3.55461 9.60023 3.34892 9.68343 3.1831 9.84983C3.01727 10.0162 2.93407 10.2219 2.9335 10.4669C2.9335 10.7124 3.0167 10.9184 3.1831 11.0848C3.3495 11.2512 3.55518 11.3341 3.80016 11.3336ZM3.80016 7.86689H14.2002C14.4457 7.86689 14.6517 7.78369 14.8181 7.61729C14.9845 7.45089 15.0674 7.2452 15.0668 7.00023C15.0668 6.75467 14.9836 6.54898 14.8172 6.38316C14.6508 6.21734 14.4451 6.13414 14.2002 6.13356H3.80016C3.55461 6.13356 3.34892 6.21676 3.1831 6.38316C3.01727 6.54956 2.93407 6.75525 2.9335 7.00023C2.9335 7.24578 3.0167 7.45176 3.1831 7.61816C3.3495 7.78456 3.55518 7.86747 3.80016 7.86689ZM3.80016 4.40023H10.7335C10.979 4.40023 11.185 4.31703 11.3514 4.15063C11.5178 3.98423 11.6007 3.77854 11.6002 3.53356C11.6002 3.28801 11.517 3.08232 11.3506 2.91649C11.1842 2.75067 10.9785 2.66747 10.7335 2.66689H3.80016C3.55461 2.66689 3.34892 2.75009 3.1831 2.91649C3.01727 3.08289 2.93407 3.28858 2.9335 3.53356C2.9335 3.77912 3.0167 3.98509 3.1831 4.15149C3.3495 4.31789 3.55518 4.4008 3.80016 4.40023Z" fill="#4476F1"/>
</svg>
                                    </div>
                                    <p className="privacy-main-bold">Browser settings: <p className="privacy-main-text"> You can manage your privacy settings to block third-party cookies and tracking.</p></p>
                                </div>
                                <div className="grey-sub-container">
                                    <div className="circle-check">
                                    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="https://www.w3.org/2000/svg">
  <path d="M17.0103 2.96957C17.0035 2.9627 15.6098 4.34029 12.8363 7.10922L10.8903 5.16317L15.0368 1.01665C12.8546 -0.29676 10.0513 0.0401881 8.23364 1.85787C7.53882 2.5523 7.03881 3.41721 6.78374 4.36586C6.52868 5.31452 6.52753 6.31356 6.78041 7.2628L6.82167 7.41637L0.323382 13.9124C0.181268 14.0545 0.181268 14.2883 0.323382 14.4304L3.5645 17.6715C3.70662 17.8136 3.94042 17.8136 4.08253 17.6715L10.5762 11.1778L10.7298 11.2191C12.6483 11.7302 14.709 11.1984 16.1393 9.76813C17.9593 7.95045 18.2962 5.14713 17.0103 2.96957Z" fill="#4476F1"/>
</svg>
                                    </div>
                                    <p className="privacy-main-bold">Privacy-focused browsers and tools: <p className="privacy-main-text"> Consider using browsers that are privacy-focused. You can also download privacy-focused browser extensions.</p></p>
                                </div>
                                
                            </div>
                </div>
                <div className="privacy-frame-32">
                    <h2 className="privacy-main-header">Children privacy</h2>
                    <p className="privacy-main-text">
                    Tylo.ai is not targeted at people under the age of majority. We do not knowingly collect or solicit data from individuals under 18 years of age. By using Tylo.ai, you confirm that you are at least 18 years of age. If you are under 18 years of age, you may only use Tylo.ai under a parent or legal guardian’s supervision. If you are a parent or legal guardian whose child has submitted personal data, please reach out to us at contact@tylo.ai to have us delete it.
                    </p>
                </div>
                <div className="privacy-frame-32">
                    <h2 className="privacy-main-header">Third-party links and adverts</h2>
                    <p className="privacy-main-text">
                    Tylo.ai may contain adverts, links, and content that lead to other websites, software, apps, and services. This Privacy Policy does not describe the privacy practices of the respective third parties. If you submit any data to any website, software or app outside of Tylo.ai, you release us from any liabilities.
                    </p>
                </div>
                <div className="privacy-frame-32">
                    <h2 className="privacy-main-header">Rights and choices</h2>
                    <p className="privacy-main-bold">In addition to other rights already provided, you have the following rights and choices with respect to how we process your data:</p>
                    
                        <div className="grey-tab-full">
                            <div className="circle-check">
                            <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="https://www.w3.org/2000/svg">
    <path d="M16.1078 0.901957C15.8102 0.736394 15.483 0.631107 15.1447 0.592116C14.8065 0.553125 14.4638 0.581194 14.1364 0.67472C13.809 0.768245 13.5033 0.925392 13.2367 1.13717C12.9701 1.34895 12.7478 1.61121 12.5826 1.90896L7.77052 10.5689L5.01649 7.81489C4.77739 7.56732 4.49137 7.36985 4.17514 7.23401C3.8589 7.09816 3.51877 7.02666 3.17461 7.02367C2.83044 7.02068 2.48912 7.08626 2.17057 7.21659C1.85202 7.34692 1.56262 7.53939 1.31924 7.78276C1.07587 8.02613 0.883407 8.31553 0.753078 8.63408C0.622748 8.95263 0.557166 9.29395 0.560156 9.63812C0.563147 9.98229 0.634651 10.3224 0.770497 10.6386C0.906342 10.9549 1.10381 11.2409 1.35137 11.48L6.53542 16.6641C7.02531 17.1552 7.68628 17.4248 8.36798 17.4248L8.72698 17.3989C9.12427 17.3433 9.50324 17.1963 9.83406 16.9694C10.1649 16.7425 10.4385 16.4419 10.6334 16.0912L17.1135 4.42711C17.2789 4.12961 17.3841 3.80244 17.4231 3.46428C17.4621 3.12611 17.4341 2.78358 17.3407 2.45623C17.2473 2.12889 17.0904 1.82315 16.8788 1.55646C16.6673 1.28978 16.4053 1.06738 16.1078 0.901957Z" fill="#4476F1"/>
    </svg>  
                            </div>
                                <p className="privacy-main-bold">Right to access <p className="privacy-main-text"> You have the right to request access to the personal data we hold about you. This includes information about the purposes of the processing, the categories of personal data involved and the recipients or categories of recipients to whom the data has been or will be disclosed. You can see the “How we may disclose data” section of this Privacy Policy to see how we may disclose your data, or you can reach out to us at contact@tylo.ai.</p></p>     
                        </div>

                        <div className="grey-tab-full">
                            <div className="circle-check">
                            <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="https://www.w3.org/2000/svg">
    <path d="M16.1078 0.901957C15.8102 0.736394 15.483 0.631107 15.1447 0.592116C14.8065 0.553125 14.4638 0.581194 14.1364 0.67472C13.809 0.768245 13.5033 0.925392 13.2367 1.13717C12.9701 1.34895 12.7478 1.61121 12.5826 1.90896L7.77052 10.5689L5.01649 7.81489C4.77739 7.56732 4.49137 7.36985 4.17514 7.23401C3.8589 7.09816 3.51877 7.02666 3.17461 7.02367C2.83044 7.02068 2.48912 7.08626 2.17057 7.21659C1.85202 7.34692 1.56262 7.53939 1.31924 7.78276C1.07587 8.02613 0.883407 8.31553 0.753078 8.63408C0.622748 8.95263 0.557166 9.29395 0.560156 9.63812C0.563147 9.98229 0.634651 10.3224 0.770497 10.6386C0.906342 10.9549 1.10381 11.2409 1.35137 11.48L6.53542 16.6641C7.02531 17.1552 7.68628 17.4248 8.36798 17.4248L8.72698 17.3989C9.12427 17.3433 9.50324 17.1963 9.83406 16.9694C10.1649 16.7425 10.4385 16.4419 10.6334 16.0912L17.1135 4.42711C17.2789 4.12961 17.3841 3.80244 17.4231 3.46428C17.4621 3.12611 17.4341 2.78358 17.3407 2.45623C17.2473 2.12889 17.0904 1.82315 16.8788 1.55646C16.6673 1.28978 16.4053 1.06738 16.1078 0.901957Z" fill="#4476F1"/>
    </svg>  
                            </div>
                                <p className="privacy-main-bold">Right to rectification <p className="privacy-main-text"> If the personal data we hold about you is inaccurate or incomplete, you have the right to request corrections or updates. You can do this by using the account management feature in your account to edit any inaccurate data.</p></p>     
                        </div>

                        <div className="grey-tab-full">
                            <div className="circle-check">
                            <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="https://www.w3.org/2000/svg">
    <path d="M16.1078 0.901957C15.8102 0.736394 15.483 0.631107 15.1447 0.592116C14.8065 0.553125 14.4638 0.581194 14.1364 0.67472C13.809 0.768245 13.5033 0.925392 13.2367 1.13717C12.9701 1.34895 12.7478 1.61121 12.5826 1.90896L7.77052 10.5689L5.01649 7.81489C4.77739 7.56732 4.49137 7.36985 4.17514 7.23401C3.8589 7.09816 3.51877 7.02666 3.17461 7.02367C2.83044 7.02068 2.48912 7.08626 2.17057 7.21659C1.85202 7.34692 1.56262 7.53939 1.31924 7.78276C1.07587 8.02613 0.883407 8.31553 0.753078 8.63408C0.622748 8.95263 0.557166 9.29395 0.560156 9.63812C0.563147 9.98229 0.634651 10.3224 0.770497 10.6386C0.906342 10.9549 1.10381 11.2409 1.35137 11.48L6.53542 16.6641C7.02531 17.1552 7.68628 17.4248 8.36798 17.4248L8.72698 17.3989C9.12427 17.3433 9.50324 17.1963 9.83406 16.9694C10.1649 16.7425 10.4385 16.4419 10.6334 16.0912L17.1135 4.42711C17.2789 4.12961 17.3841 3.80244 17.4231 3.46428C17.4621 3.12611 17.4341 2.78358 17.3407 2.45623C17.2473 2.12889 17.0904 1.82315 16.8788 1.55646C16.6673 1.28978 16.4053 1.06738 16.1078 0.901957Z" fill="#4476F1"/>
    </svg>  
                            </div>
                                <p className="privacy-main-bold">Right to erasure (Right to be forgotten) <p className="privacy-main-text"> You have the right to request the deletion of your data under certain circumstances. This right is not absolute and may not apply in certain situations, such as when processing is necessary for compliance with a legal obligation. You can use the “Delete Account” button via the account management section of your account to request the erasure of your data.</p></p>     
                        </div>

                        <div className="grey-tab-full">
                            <div className="circle-check">
                            <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="https://www.w3.org/2000/svg">
    <path d="M16.1078 0.901957C15.8102 0.736394 15.483 0.631107 15.1447 0.592116C14.8065 0.553125 14.4638 0.581194 14.1364 0.67472C13.809 0.768245 13.5033 0.925392 13.2367 1.13717C12.9701 1.34895 12.7478 1.61121 12.5826 1.90896L7.77052 10.5689L5.01649 7.81489C4.77739 7.56732 4.49137 7.36985 4.17514 7.23401C3.8589 7.09816 3.51877 7.02666 3.17461 7.02367C2.83044 7.02068 2.48912 7.08626 2.17057 7.21659C1.85202 7.34692 1.56262 7.53939 1.31924 7.78276C1.07587 8.02613 0.883407 8.31553 0.753078 8.63408C0.622748 8.95263 0.557166 9.29395 0.560156 9.63812C0.563147 9.98229 0.634651 10.3224 0.770497 10.6386C0.906342 10.9549 1.10381 11.2409 1.35137 11.48L6.53542 16.6641C7.02531 17.1552 7.68628 17.4248 8.36798 17.4248L8.72698 17.3989C9.12427 17.3433 9.50324 17.1963 9.83406 16.9694C10.1649 16.7425 10.4385 16.4419 10.6334 16.0912L17.1135 4.42711C17.2789 4.12961 17.3841 3.80244 17.4231 3.46428C17.4621 3.12611 17.4341 2.78358 17.3407 2.45623C17.2473 2.12889 17.0904 1.82315 16.8788 1.55646C16.6673 1.28978 16.4053 1.06738 16.1078 0.901957Z" fill="#4476F1"/>
    </svg>  
                            </div>
                                <p className="privacy-main-bold">Right to restriction of processing <p className="privacy-main-text"> You have the right to request the restriction of processing of your data under certain circumstances, such as when the accuracy of the data is contested, or the processing is unlawful.</p></p>     
                        </div>

                        <div className="grey-tab-full">
                            <div className="circle-check">
                            <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="https://www.w3.org/2000/svg">
    <path d="M16.1078 0.901957C15.8102 0.736394 15.483 0.631107 15.1447 0.592116C14.8065 0.553125 14.4638 0.581194 14.1364 0.67472C13.809 0.768245 13.5033 0.925392 13.2367 1.13717C12.9701 1.34895 12.7478 1.61121 12.5826 1.90896L7.77052 10.5689L5.01649 7.81489C4.77739 7.56732 4.49137 7.36985 4.17514 7.23401C3.8589 7.09816 3.51877 7.02666 3.17461 7.02367C2.83044 7.02068 2.48912 7.08626 2.17057 7.21659C1.85202 7.34692 1.56262 7.53939 1.31924 7.78276C1.07587 8.02613 0.883407 8.31553 0.753078 8.63408C0.622748 8.95263 0.557166 9.29395 0.560156 9.63812C0.563147 9.98229 0.634651 10.3224 0.770497 10.6386C0.906342 10.9549 1.10381 11.2409 1.35137 11.48L6.53542 16.6641C7.02531 17.1552 7.68628 17.4248 8.36798 17.4248L8.72698 17.3989C9.12427 17.3433 9.50324 17.1963 9.83406 16.9694C10.1649 16.7425 10.4385 16.4419 10.6334 16.0912L17.1135 4.42711C17.2789 4.12961 17.3841 3.80244 17.4231 3.46428C17.4621 3.12611 17.4341 2.78358 17.3407 2.45623C17.2473 2.12889 17.0904 1.82315 16.8788 1.55646C16.6673 1.28978 16.4053 1.06738 16.1078 0.901957Z" fill="#4476F1"/>
    </svg>  
                            </div>
                                <p className="privacy-main-bold">Right to object <p className="privacy-main-text"> You have the right to object to the processing of personal data for certain purposes, such as direct marketing or processing based on legitimate interests. We will assess your objection and cease processing unless we have compelling legitimate grounds for the processing that override your interests, rights and freedoms.</p></p>     
                        </div>

                        <div className="grey-tab-full">
                            <div className="circle-check">
                            <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="https://www.w3.org/2000/svg">
    <path d="M16.1078 0.901957C15.8102 0.736394 15.483 0.631107 15.1447 0.592116C14.8065 0.553125 14.4638 0.581194 14.1364 0.67472C13.809 0.768245 13.5033 0.925392 13.2367 1.13717C12.9701 1.34895 12.7478 1.61121 12.5826 1.90896L7.77052 10.5689L5.01649 7.81489C4.77739 7.56732 4.49137 7.36985 4.17514 7.23401C3.8589 7.09816 3.51877 7.02666 3.17461 7.02367C2.83044 7.02068 2.48912 7.08626 2.17057 7.21659C1.85202 7.34692 1.56262 7.53939 1.31924 7.78276C1.07587 8.02613 0.883407 8.31553 0.753078 8.63408C0.622748 8.95263 0.557166 9.29395 0.560156 9.63812C0.563147 9.98229 0.634651 10.3224 0.770497 10.6386C0.906342 10.9549 1.10381 11.2409 1.35137 11.48L6.53542 16.6641C7.02531 17.1552 7.68628 17.4248 8.36798 17.4248L8.72698 17.3989C9.12427 17.3433 9.50324 17.1963 9.83406 16.9694C10.1649 16.7425 10.4385 16.4419 10.6334 16.0912L17.1135 4.42711C17.2789 4.12961 17.3841 3.80244 17.4231 3.46428C17.4621 3.12611 17.4341 2.78358 17.3407 2.45623C17.2473 2.12889 17.0904 1.82315 16.8788 1.55646C16.6673 1.28978 16.4053 1.06738 16.1078 0.901957Z" fill="#4476F1"/>
    </svg>  
                            </div>
                                <p className="privacy-main-bold">Right to data portability <p className="privacy-main-text"> You have the right to receive a copy of your data in a structured, commonly used and machine-readable format and to transmit that data to another data controller without hindrance from us.</p></p>     
                        </div>

                        <div className="grey-tab-full">
                            <div className="circle-check">
                            <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="https://www.w3.org/2000/svg">
    <path d="M16.1078 0.901957C15.8102 0.736394 15.483 0.631107 15.1447 0.592116C14.8065 0.553125 14.4638 0.581194 14.1364 0.67472C13.809 0.768245 13.5033 0.925392 13.2367 1.13717C12.9701 1.34895 12.7478 1.61121 12.5826 1.90896L7.77052 10.5689L5.01649 7.81489C4.77739 7.56732 4.49137 7.36985 4.17514 7.23401C3.8589 7.09816 3.51877 7.02666 3.17461 7.02367C2.83044 7.02068 2.48912 7.08626 2.17057 7.21659C1.85202 7.34692 1.56262 7.53939 1.31924 7.78276C1.07587 8.02613 0.883407 8.31553 0.753078 8.63408C0.622748 8.95263 0.557166 9.29395 0.560156 9.63812C0.563147 9.98229 0.634651 10.3224 0.770497 10.6386C0.906342 10.9549 1.10381 11.2409 1.35137 11.48L6.53542 16.6641C7.02531 17.1552 7.68628 17.4248 8.36798 17.4248L8.72698 17.3989C9.12427 17.3433 9.50324 17.1963 9.83406 16.9694C10.1649 16.7425 10.4385 16.4419 10.6334 16.0912L17.1135 4.42711C17.2789 4.12961 17.3841 3.80244 17.4231 3.46428C17.4621 3.12611 17.4341 2.78358 17.3407 2.45623C17.2473 2.12889 17.0904 1.82315 16.8788 1.55646C16.6673 1.28978 16.4053 1.06738 16.1078 0.901957Z" fill="#4476F1"/>
    </svg>  
                            </div>
                                <p className="privacy-main-bold">Right to withdraw consent <p className="privacy-main-text"> If we rely on your consent for the processing of your data, you have the right to withdraw that consent at any time. The withdrawal of consent does not affect the lawfulness of processing based on consent before its withdrawal.</p></p>     
                        </div>

                        <div className="grey-tab-full">
                            <div className="circle-check">
                            <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="https://www.w3.org/2000/svg">
    <path d="M16.1078 0.901957C15.8102 0.736394 15.483 0.631107 15.1447 0.592116C14.8065 0.553125 14.4638 0.581194 14.1364 0.67472C13.809 0.768245 13.5033 0.925392 13.2367 1.13717C12.9701 1.34895 12.7478 1.61121 12.5826 1.90896L7.77052 10.5689L5.01649 7.81489C4.77739 7.56732 4.49137 7.36985 4.17514 7.23401C3.8589 7.09816 3.51877 7.02666 3.17461 7.02367C2.83044 7.02068 2.48912 7.08626 2.17057 7.21659C1.85202 7.34692 1.56262 7.53939 1.31924 7.78276C1.07587 8.02613 0.883407 8.31553 0.753078 8.63408C0.622748 8.95263 0.557166 9.29395 0.560156 9.63812C0.563147 9.98229 0.634651 10.3224 0.770497 10.6386C0.906342 10.9549 1.10381 11.2409 1.35137 11.48L6.53542 16.6641C7.02531 17.1552 7.68628 17.4248 8.36798 17.4248L8.72698 17.3989C9.12427 17.3433 9.50324 17.1963 9.83406 16.9694C10.1649 16.7425 10.4385 16.4419 10.6334 16.0912L17.1135 4.42711C17.2789 4.12961 17.3841 3.80244 17.4231 3.46428C17.4621 3.12611 17.4341 2.78358 17.3407 2.45623C17.2473 2.12889 17.0904 1.82315 16.8788 1.55646C16.6673 1.28978 16.4053 1.06738 16.1078 0.901957Z" fill="#4476F1"/>
    </svg>  
                            </div>
                                <p className="privacy-main-bold">Right to reporting <p className="privacy-main-text"> If you believe we are unlawfully processing your data, you have the right to report us to any data protection authority in your location. For example, UK users can report to the Report to the Information Commissioner’s Office (ICO).</p></p>     
                        </div>
                    
                </div>
                <div className="privacy-frame-32">
                    <h2 className="privacy-main-header">Sale of personal data</h2>
                    <p className="privacy-main-text">
                    We are not in the business of selling personal data to third parties for their direct marketing purposes. However, under certain laws, such as the California Consumer Privacy Act (CCPA), as amended by the California Privacy Rights Act (CPRA), the sharing of personal data with third parties in connection with advertising Tylo.ai (specifically interest-based advertising) is regarded a “sale of personal data” for monetary consideration. We are required to provide an opt-out means for you to opt out. You can click on the “Do Not Sell My Information” banner to learn more. You may also contact us at contact@tylo.ai for more.
                    </p>
                </div>
                <div className="privacy-frame-32">
                    <h2 className="privacy-main-header">Do Not Track signals</h2>
                    <p className="privacy-main-text">
                    We do not currently respond to Do Not Track signals from your browser, as this feature is currently not consistent among industry participants. 
                    </p>
                </div>
                <div className="privacy-frame-32">
                    <h2 className="privacy-main-header">Updates</h2>
                    <p className="privacy-main-text">
                    We may update this Privacy Policy from time to time to reflect changes in our practices, technology, legal requirements and for other reasons. If we make changes, we will revise the "Effective Date" at the top of this Privacy Policy. We may notify you of changes via a pop-up banner or a mail to your account email address. We encourage you to review this Privacy Policy periodically to stay informed about how we are protecting your data. Your continued use of Tylo.ai after the posting of any updated Privacy Policy shall constitute your agreement to be bound by any such changes.
                    </p>
                </div>
            </div>
            
            <div className="all-container">
                <div className="all-rights-text">2024 Tylo AI Ltd. All rights reserved. </div>   
                <div className="all-rights-highlight" onClick={handleTOUClick}>
                    Terms of use  
                </div>
                <div className="all-rights-highlight" onClick={handlePrivacyPolicyClick}>
                    Privacy Policy 
                </div> 
                <a href="https://www.linkedin.com/company/tyloai/" target="_blank" rel="noopener noreferrer">
                <svg width="39" height="39" viewBox="0 0 39 39" fill="none" xmlns="https://www.w3.org/2000/svg">
  <g clip-path="url(#clip0_2801_871)">
    <path d="M29.4609 0.75H9.53906C4.685 0.75 0.75 4.685 0.75 9.53906V29.4609C0.75 34.315 4.685 38.25 9.53906 38.25H29.4609C34.315 38.25 38.25 34.315 38.25 29.4609V9.53906C38.25 4.685 34.315 0.75 29.4609 0.75Z" fill="white"/>
    <path d="M29.4609 0.75H9.53906C4.685 0.75 0.75 4.685 0.75 9.53906V29.4609C0.75 34.315 4.685 38.25 9.53906 38.25H29.4609C34.315 38.25 38.25 34.315 38.25 29.4609V9.53906C38.25 4.685 34.315 0.75 29.4609 0.75Z" fill="#4476F1"/>
    <path d="M27.8079 32.6375H32.0955C32.2508 32.6375 32.3998 32.5757 32.5097 32.4659C32.6196 32.356 32.6814 32.207 32.6814 32.0517L32.6836 22.9928C32.6836 18.258 31.6633 14.6186 26.1302 14.6186C24.0268 14.5403 22.0433 15.6246 20.9729 17.4352C20.9677 17.444 20.9597 17.4508 20.9502 17.4546C20.9407 17.4585 20.9303 17.4591 20.9204 17.4564C20.9105 17.4538 20.9018 17.4479 20.8955 17.4398C20.8893 17.4317 20.8858 17.4218 20.8857 17.4116V15.6416C20.8857 15.4862 20.824 15.3372 20.7141 15.2273C20.6042 15.1174 20.4552 15.0557 20.2998 15.0557H16.2309C16.0755 15.0557 15.9265 15.1174 15.8166 15.2273C15.7067 15.3372 15.645 15.4862 15.645 15.6416V32.0508C15.645 32.2062 15.7067 32.3552 15.8166 32.4651C15.9265 32.575 16.0755 32.6367 16.2309 32.6367H20.5182C20.6736 32.6367 20.8226 32.575 20.9325 32.4651C21.0424 32.3552 21.1042 32.2062 21.1042 32.0508V23.9395C21.1042 21.646 21.5392 19.4249 24.3826 19.4249C27.1856 19.4249 27.2219 22.0493 27.2219 24.088V32.0515C27.2219 32.2069 27.2837 32.3559 27.3935 32.4658C27.5034 32.5757 27.6525 32.6375 27.8079 32.6375ZM6.31641 9.48457C6.31641 11.2225 7.74712 12.6524 9.48516 12.6524C11.2228 12.6523 12.6526 11.2214 12.6526 9.48384C12.6523 7.74624 11.2223 6.31641 9.48457 6.31641C7.74639 6.31641 6.31641 7.74668 6.31641 9.48457ZM7.33579 32.6375H11.6288C11.7842 32.6375 11.9332 32.5757 12.0431 32.4658C12.153 32.3559 12.2147 32.2069 12.2147 32.0515V15.6416C12.2147 15.4862 12.153 15.3372 12.0431 15.2273C11.9332 15.1174 11.7842 15.0557 11.6288 15.0557H7.33579C7.18039 15.0557 7.03136 15.1174 6.92147 15.2273C6.81159 15.3372 6.74985 15.4862 6.74985 15.6416V32.0515C6.74985 32.2069 6.81159 32.3559 6.92147 32.4658C7.03136 32.5757 7.18039 32.6375 7.33579 32.6375Z" fill="white"/>
  </g>
  <defs>
    <clipPath id="clip0_2801_871">
      <rect width="37.5" height="37.5" fill="white" transform="translate(0.75 0.75)"/>
    </clipPath>
  </defs>
</svg>
</a>
            </div>

        </div>

    );
}

export default Privacy;