import React, { useEffect, useState} from "react";
// import ReactDOM from "react-dom";n 
import Tylo_icon from "../../assets/images/Tylo_logo.svg";
// import Dropdown_open from "../../assets/images/Dropdown_open.svg" ;
// import Dropdown_closed from "../../assets/images/Dropdown_closed.svg";
import "../settings/settings.scss";
import { gapi } from "gapi-script";
import axios from "axios";
import { useNavigate } from "react-router-dom";
// import { v4 as uuidv4 } from 'uuid';


function Settings() {
    const googleProfileImageUrl = localStorage.getItem("googleProfileImageUrl");
    const navigate = useNavigate();
    const [username, setUsername] = useState('');
    const [email, setEmail] = useState('');
    const token = localStorage.getItem('token');
    const Name = localStorage.getItem('tyloName');
    const [credits, setCredits] = useState('');


    useEffect(() => {
        // Load the Google API client library
        const script = document.createElement("script");
        script.src = "https://apis.google.com/js/api.js";
        script.onload = () => {
          // Initialize the Google API client library
          gapi.load("auth2", () => {
            gapi.auth2.init({
              client_id:
                "584832623015-02ioa5kbjqp9agd30pdiifln0bhb5trb.apps.googleusercontent.com",
              scope: "profile email",
              redirect_uri: "https://tylo.ai/", 
              cookiepolicy: "single_host_origin",
            });
          });
        };
        document.body.appendChild(script);

        fetch("https://backend-dot-tylo-project.nw.r.appspot.com/api/v1/profile", {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${token}`,
            },
          })
          .then(response => response.json())
          .then(data => {
            setUsername(data.username);
            setEmail(data.email);
            setCredits(data.credits);
            console.log('email:', data.email)
        })
        .catch(error => console.error('Error fetching user data:', error));
        
      }, );


    const navigateToSettings = () => {
        navigate('/settings'); // Navigate to /#features
    };
      
    const updateBackendCredits = async (newCredits) => {
        try {
            const token = localStorage.getItem('token'); // Assuming you're storing the token in localStorage
            const response = await axios.post("https://backend-dot-tylo-project.nw.r.appspot.com/api/v1/updateCredits", {
                credits: newCredits, // Sending the new credits amount to the backend
            }, {
                headers: {
                    'Authorization': `Bearer ${token}`, // Use the JWT token from localStorage
                }
            });
    
            // console.log("Response data:", response.data);
    
            // Update your frontend state to reflect the new credits
            setCredits(response.data.credits);
            // Optionally update local storage or other caches if necessary
        } catch (error) {
            console.error('Error updating credits:', error);
            // Handle error (show feedback to the user, etc.)
            // If you want to specifically handle HTTP error statuses, you can check error.response.status
        }
    };

      const shareOnLinkedIn = async () => {
        const referralLink = `https://www.tylo.ai/?ref=`; 
        const linkedInUrl = `https://www.linkedin.com/sharing/share-offsite/?url=${encodeURIComponent(referralLink)}`;
    
        window.open(linkedInUrl, '_blank', 'toolbar=yes,scrollbars=yes,resizable=yes,top=500,left=500,width=400,height=400');
    
       
        setTimeout(() => {
            if (window.confirm("Please confirm that you have shared on LinkedIn to receive your credits.")) {
                updateCreditsForShare(20); // Pass the amount of credits you want to award for sharing
            } else {
                alert("Credits can only be added if you confirm sharing on LinkedIn.");
            }
        }, 4000);
    };
    
    const updateCreditsForShare = async () => {
        const newCredits = 20; // Define how many credits to add
        const submittedCredits = credits + newCredits;
        try {
            await updateBackendCredits(submittedCredits); // Assuming updateBackendCredits is defined
            alert("Thank you for sharing! 20 credits have been added to your account.");
        } catch (error) {
            console.error("Failed to update credits:", error);
            alert("Failed to update credits. Please try again.");
        }
    };
    

    const handleSignOutClick = () => {
        // Sign out from your application
        // This might involve removing the user's session or token from local storage
        localStorage.removeItem('token'); // For example, if you store a session token

        // If using Google sign-in and you want to sign the user out of Google as well
        const auth2 = gapi.auth2.getAuthInstance();
        if (auth2 != null) {
            auth2.signOut().then(auth2.disconnect().then(() => {
                console.log('User signed out of Google.');
                navigate('/#');
            }));
        }

        navigate('/#'); 
    };

    return (
        <div className="feature-page">
            <div className="feature-page-icon">
                    <img src={Tylo_icon} alt="Tylo Icon" className="tylo-icon" />
                    <div className="tabs">
                        <div className="top-bar-tab-current_page">
                            <a href="/#features" className="top-bar-text">Features</a> {/* Link to the Features page */}
                        </div>
                        <div className="top-bar-tab">
                            <a href="/#about" className="top-bar-text">About Us</a> {/* Link to the About page */}
                        </div>
                    </div>
                    <img src={googleProfileImageUrl} alt="Profile Icon" onClick={navigateToSettings} className="profile-icon" />
                    <div className="login-button" onClick={handleSignOutClick}>
                        <span className="login-text" >Sign Out</span>
                    </div>
            </div>

            <div className="gradient-rectangle">
                <h2 className="feature-title">Settings</h2>
            </div>
            <div className="profile-main">
                <div className="profile-container">
                    <div className="name-header">
                        <img src={googleProfileImageUrl} alt="Profile Icon" className="name-image" />
                        <h1 className="name-text">{Name}</h1>
                    </div>
                    <div className="settings-tabs">
                        <div className="settings-sub-tab">
                            <h2 className="sub-tab-header">Username</h2>
                            <p className="grey-text">{username || 'Loading...'}</p>
                            <div className="sub-tab-end">
                                <p className="change">change</p>
                            </div>
                        </div>
                    </div>
                    <div className="settings-tabs">
                        <div className="settings-sub-tab">
                            <h2 className="sub-tab-header">Email</h2>
                            <p className="grey-text">{email || 'Loading...'}</p>
                            <div className="sub-tab-end">
                                <p className="change">change</p>
                            </div>
                        </div>
                    </div>
                    <div className="settings-tabs">
                        <div className="settings-sub-tab">
                            <h2 className="sub-tab-header">Credits</h2>
                            <p className="grey-text">{credits || 'Loading...'}</p>
                            <div className="sub-tab-end">
                                <p className="change" onClick={shareOnLinkedIn}>add more</p>
                            </div>
                        </div>
                    </div>
                    <div className="settings-tabs">
                        
                    </div>
                </div>
            </div>
        </div>

    );

}

export default Settings
