import React, { useEffect, useState} from "react";
// import ReactDOM from "react-dom";
import Tylo_icon from "../../assets/images/Tylo_logo.svg";
// import Dropdown_open from "../../assets/images/Dropdown_open.svg" ;
// import Dropdown_closed from "../../assets/images/Dropdown_closed.svg";
import "../features/features.scss";
import { gapi } from "gapi-script";
import axios from "axios";
import { useNavigate } from "react-router-dom";
// import { useLocation } from 'react-router-dom';
import { setToken } from "../../services/userService";
import useQuery from '../../hooks/useQuery';

// import { createClient } from '@supabase/supabase-js'


function Features() {
    console.log('Features component rendering')

    // Initialize state for storing the data
    // const [publishers, setPublishers] = useState([]);
    // const [ setLoading] = useState(true);
    // const [ setError] = useState(null);
    // Create a single supabase client for interacting with your database
    // const supabase = createClient('https://kcfgjenxuummtzdtprsh.supabase.co', 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6ImtjZmdqZW54dXVtbXR6ZHRwcnNoIiwicm9sZSI6ImFub24iLCJpYXQiOjE3MDE5Nzg3NjMsImV4cCI6MjAxNzU1NDc2M30.F8A-w41SZeMzbkWTYl2ROoSqB05b-nHPwa-tAKl_PJY')

    const navigate = useNavigate();
    const [showInstructions, setShowInstructions] = useState(false);
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [org, setOrg] = useState('');
    const [role, setRole] = useState('');
    const [showModal, setShowModal] = useState(false);
    // const [signUp, setSignUp] = useState(false);
    const [isSignUp, setIsSignUp] = useState(false);
    const [confirmPassword, setConfirmPassword] = useState('');
    const [formErrors, setFormErrors] = useState({});
    const [message, setMessage] = useState('');
    const [buttonColor, setButtonColor] = useState('submit-button');
    const [buttonText, setButtonText] = useState('Submit');
    const [referralCode, setReferralCode] = useState('');
    const query = useQuery();

    // const { data, error } = supabase
    // .from('publishers')
    // .select('publisher_id')
    // console.log('the publisher is', data)


    const handlePrivacyPolicyClick = () => {
        window.open(`${window.location.origin}/#privacy`, '_blank');
    };
    const handleTOUClick = () => {
        window.open(`${window.location.origin}/#termsofuse`, '_blank');
    }
    const closeModal = (e) => {
        // Ensure that it's the backdrop that's being clicked, not the modal content
        if (e.target.classList.contains('modal-backdrop')) {
            setShowModal(false);
        }
    };

    useEffect(() => {
        const refCode = query.get('ref');
        if (refCode) {
            setReferralCode(refCode);
        }
        // Load the Google API client library
        const script = document.createElement("script");
        script.src = "https://apis.google.com/js/api.js";
        script.onload = () => {
          // Initialize the Google API client library
          gapi.load("auth2", () => {
            gapi.auth2.init({
              client_id:
                "584832623015-02ioa5kbjqp9agd30pdiifln0bhb5trb.apps.googleusercontent.com",
              scope: "profile email",
              redirect_uri: "https://tylo.ai/",
              cookiepolicy: "single_host_origin",
            });
          });
        };
        document.body.appendChild(script);
      
      }, [query]);


      const validateForm = () => {
        let errors = {};
        let formIsValid = true;
      
        // Email validation
        if (!email) {
          formIsValid = false;
          errors["email"] = "Email is required";
        } else if (!/\S+@\S+\.\S+/.test(email)) {
          formIsValid = false;
          errors["email"] = "Email is not valid";
        }
      
        // Password validation
        if (!password) {
          formIsValid = false;
          errors["password"] = "Password is required";
        }
      
        // Confirm Password validation
        if (password !== confirmPassword) {
          formIsValid = false;
          errors["confirmPassword"] = "Passwords do not match";
        }

        setFormErrors(errors);
        return formIsValid;
      };



      const getAppState = () => {
        const userId = localStorage.getItem('tyloEmail'); 
        return {
            userId,
          // Add other state elements as needed
        };
      };

    const handleSubmit = () => {
        const token = localStorage.getItem('token'); // Ensure you're getting the auth token correctly
        const appState = getAppState(); 
        

        setButtonText('Submitted');
        setButtonColor('submit-button-green');
    
        // Replace the URL with your actual endpoint
        const url = "https://backend-dot-tylo-project.nw.r.appspot.com/api/v1/message";
    
        axios.post(url, {
          message,
          appState: JSON.stringify(appState),
        }, {
          headers: {
            "Authorization": `Bearer ${token}`, // Adjust this according to your token auth setup
            "Content-Type": "application/json",
          },
        }).then(response => {
        //   console.log('Message submitted:', response.data);
          setMessage(''); // Clear the message input after successful submission
        }).catch(error => {
          console.error('Error submitting message:', error);
        });

        setTimeout(() => {
            setButtonText('Submit');
            setButtonColor('submit-button'); // Revert to the initial class
        }, 3000);
      };
      
    

    const handleSignInClick = () => {
        gapi.auth2
          .getAuthInstance()
          .signIn()
          .then((googleUser) => {
            const profile = googleUser.getBasicProfile();
            const profileImageUrl = profile.getImageUrl();
            axios
              .post(
                "https://backend-dot-tylo-project.nw.r.appspot.com/api/v1/auth",
                { idToken: googleUser.xc.id_token, referralCode, },
                {
                  headers: {
                    "Content-Type": "application/json",
                    Accept: "application/json",
                  },
                }
              )
              .then((response) => {
                // console.log("Axios response data:", response.data);
                // Save the JWT token to local storage
                localStorage.setItem("token", response.data.token);
                localStorage.setItem("tyloEmail",response.data.user.email)
                localStorage.setItem("tyloName",response.data.user.name)
                localStorage.setItem("googleProfileImageUrl", profileImageUrl);
                // console.log("email",response.data.user.email)
                // console.log("name", response.data.user.name)
                // console.log("username", response.data.user.username)
                setToken(response.data.token);

                // Navigate to /#features page
                navigate("features"); // Adjust as per your routing setup
            
                if (response.data.user.name){
                  localStorage.setItem("name", response.data.user.name)
                  localStorage.setItem("username", response.data.user.username)
    
                //   navigate("/welcome?profile_completed=true");
                }
                // else{navigate("/welcome?profile_completed=false");}
                
              });
            // console.log("User signed in.", googleUser.xc.id_token);
          });
      };

      const handleSignIn = async () => {
        // Prevent sign-in if email or password fields are empty
        if (!email || !password) {
            alert("Please enter both email and password.");
            return;
        }
    
        try {
            // Make a POST request to your sign-in API endpoint
            const response = await axios.post('https://backend-dot-tylo-project.nw.r.appspot.com/api/v1/auth', {
                email: email,
                password: password
            }, {
                headers: {
                    'Content-Type': 'application/json'
                },
            });
    
            // Assuming your API returns a token on successful authentication
            if (response.data.token) {
                // console.log('Sign-in successful', response.data);
                // Save the received token to local storage or context/state management library
                localStorage.setItem('token', response.data.token);
                // Optionally save other user info if needed
                localStorage.setItem('userEmail', email);
    
                // Redirect or change UI upon successful sign-in
                navigate('/features'); // Or any other target location after successful login
            } else {
                // Handle no token returned
                alert('Login failed: No token received');
            }
        } catch (error) {
            console.error('Sign-in error', error);
            // Display an error message or handle errors (such as wrong credentials)
            alert('Login failed: ' + (error.response?.data?.error || 'Password or Email wrong'));
        }
    };
    

      const handleSignUp = (e) => {
        e.preventDefault();
        if (validateForm()) {
            axios.post('https://backend-dot-tylo-project.nw.r.appspot.com/api/v1/auth/signup', {
                email: email,
                password: password, // Ensure your backend endpoint is secure and handles password encryption.
            }, {
                headers: {
                    'Content-Type': 'application/json',
                },
            }).then((response) => {
                // console.log('Signup successful', response.data);
                localStorage.setItem('token', response.data.token);
                // Additional logic upon successful sign-up, such as redirecting the user
                navigate('/features');
            }).catch((error) => {
                const message = error.response?.data?.message || 'Signup failed. Please try again.';
                console.error('Signup error', message );
                // Handle sign-up errors, e.g., display an error message
            });
        }
    };
    

    console.log('Feature component about to render JSX');
    return (
        <div className="feature-page">
            
                <div className="feature-page-icon">
                    <img src={Tylo_icon} alt="Tylo Icon" className="tylo-icon" />
                    <div className="tabs">
                        <div className="top-bar-tab-current_page">
                            <a href="/#" className="top-bar-text">Features</a> {/* Link to the Features page */}
                        </div>
                        <div className="top-bar-tab">
                            <a href="/#about" className="top-bar-text">About Us</a> {/* Link to the About page */}
                        </div>
                    </div>
                    <div className="login-button" >
                    <span className="login-text" onClick={() => setShowModal(true)}>Login / Signup</span>
                    {showModal && (
                        <div className="modal-backdrop" onClick={closeModal}>
                            <div className="signin-form-modal" onClick={(e) => e.stopPropagation()}>
                                <div className="signin-header">{isSignUp ? 'Sign Up' : 'Sign In'}</div>
                                <div className="signin-text">
                                    {isSignUp ? 'Already have an account? ' : 'Don’t have an account? '}
                                    <span 
                                        style={{color: 'blue', textDecoration: 'underline', cursor: 'pointer'}} 
                                        onClick={() => setIsSignUp(!isSignUp)}
                                    >
                                        {isSignUp ? 'Sign In' : 'Sign Up'}
                                    </span>
                                </div>
                                <div className="google-signin" onClick={handleSignInClick}>
                                    <svg width="19" height="18" viewBox="0 0 19 18" fill="none" xmlns="https://www.w3.org/2000/svg" style={{ marginRight: '10px' }}>
                                        <path d="M16.8541 7.53113H16.25V7.5H9.5V10.5H13.7386C13.1203 12.2464 11.4586 13.5 9.5 13.5C7.01487 13.5 5 11.4851 5 9C5 6.51487 7.01487 4.5 9.5 4.5C10.6471 4.5 11.6908 4.93275 12.4854 5.63962L14.6068 3.51825C13.2673 2.26987 11.4755 1.5 9.5 1.5C5.35812 1.5 2 4.85812 2 9C2 13.1419 5.35812 16.5 9.5 16.5C13.6419 16.5 17 13.1419 17 9C17 8.49713 16.9482 8.00625 16.8541 7.53113Z" fill="#FFC107"/>
                                        <path d="M2.86523 5.50912L5.32936 7.31625C5.99611 5.6655 7.61086 4.5 9.50048 4.5C10.6476 4.5 11.6912 4.93275 12.4859 5.63962L14.6072 3.51825C13.2677 2.26987 11.476 1.5 9.50048 1.5C6.61973 1.5 4.12148 3.12637 2.86523 5.50912Z" fill="#FA1321"/>
                                        <path d="M9.50012 16.5C11.4374 16.5 13.1976 15.7586 14.5285 14.553L12.2072 12.5888C11.4542 13.1591 10.5182 13.5 9.50012 13.5C7.54937 13.5 5.89299 12.2561 5.26899 10.5203L2.82324 12.4046C4.06449 14.8335 6.58524 16.5 9.50012 16.5Z" fill="#4CAF50"/>
                                        <path d="M16.8541 7.53113H16.25V7.5H9.5V10.5H13.7386C13.4416 11.3389 12.902 12.0622 12.206 12.5891L12.2071 12.5884L14.5284 14.5526C14.3641 14.7019 17 12.75 17 9C17 8.49713 16.9482 8.00625 16.8541 7.53113Z" fill="#1976D2"/>
                                    </svg>
                                    Continue with Google
                                    </div>
                                <div className="or-frame">
                                    <div className="or-rec"></div>
                                    <div className="or-text">or</div>
                                    <div className="or-rec"></div>
                                </div>
                                <div className="email-container">
                                    <label htmlFor="email">Email</label>
                                    <input id="email" type="email" value={email} className="text-bar" onChange={(e) => setEmail(e.target.value)}/>
                                </div>
                                <div className="password-container">
                                    <label htmlFor="password">Password</label>
                                    <input id="password" type="password" value={password} className="text-bar" onChange={(e) => setPassword(e.target.value)}/>
                                </div>
                                {isSignUp && (
                                    <>
                                        <div className="password-container">
                                            <label htmlFor="confirm-password">Confirm Password</label>
                                            <input id="confirm-password" type="password" value={confirmPassword} className="text-bar" onChange={(e) => setConfirmPassword(e.target.value)}/>
                                            {password !== confirmPassword && confirmPassword && (
                                                <div style={{ color: 'red' }}>Passwords do not match</div>
                                            )}
                                        </div>
                                        <div className="email-container">
                                            <label htmlFor="organisation">Organisation</label>
                                            <input id="organisation" type="text" value={org} className="text-bar" onChange={(e) => setOrg(e.target.value)}/>
                                        </div>
                                        <div className="email-container">
                                            <label htmlFor="role">Role</label>
                                            <input id="role" type="text" value={role} className="text-bar" onChange={(e) => setRole(e.target.value)}/>
                                        </div>
                                        <div className="error-message">{formErrors["email"]}</div>
                                        <div className="error-message">{formErrors["password"]}</div>
                                    </>
                                )}
                                <button className="signin-button" onClick={isSignUp ? handleSignUp : handleSignIn}>
                                    <span className="signin-button-text">{isSignUp ? 'Sign Up' : 'Sign In'}</span>
                                </button>
                            </div>
                        </div>
                    )}

                    </div>
                </div>

          <div className="gradient-rectangle">
            <h2 className="feature-title">Deeptech Innovation Assistant</h2>
            <p className="feature-content">
                Sign in to unlock full features
            </p>
            <div className="tab-horizontal-container">
                <div className="sign-in" >
                    <span className="sign-in-text" >Inquire</span>
                </div>
                <div className="sign-in-disabled" >
                    <span className="sign-in-text-disabled" onClick={() => setShowModal(true)}>Track</span>
                </div>
            </div>
          </div>
          <div className="feature-page-2">
            <div className="Inquire_feature-frame-108">
                <h2 className="inquire-header">Inquire Anything</h2>
                <div className="inquire-frame-95">
                    <div className="helper-container">
                        <p className="inquire-text-1">
                            The start point of your evidence-based innovation
                        </p>
                        <div className="instructions-container">
                            <div className="instructions">
                                Instructions 
                            </div>
                            <div className="instructions-toggle dropdown-button" onClick={(e) => { e.stopPropagation(); setShowInstructions(!showInstructions); }}>
                                    {showInstructions ?  '▲' : '▼'}
                            </div>
                        </div>
                    </div>
                    {showInstructions && (
                    <div className="instruction-card">
                        <div className="inst-top-col">
                            <div className="inst-item">
                                <h1 className="inst-card-H">What is it for</h1>
                                <div className="inst-card-p">
                                    <ul className="inst-card-ul">
                                        <li>Get an answer to deeptech questions</li>
                                        <li>Learn innovative technology topics or concepts</li>
                                        <li>Find evidence for hypothesis & assumptions</li>
                                    </ul>
                                </div>
                            </div>
                            <div className="inst-item">
                                <h1 className="inst-card-H">What is it not a good fit for</h1>
                                <div className="inst-card-p">
                                   topics especially for short or general questions, it might not be your best bet. and anything that can hardly find clues from science publication and patent ocean
                                </div>
                            </div>
                            <div className="inst-item">
                                <h1 className="inst-card-H">Responding time</h1>
                                <div className="inst-card-p">
                                    typically 10 to 40 seconds
                                </div>
                            </div>
                        </div>
                        <div className="inst-top-col">
                            <div className="inst-item">
                                <h1 className="inst-card-H">Optimise your input</h1>
                                <div className="inst-card-p">
                                    <ul className="inst-card-ul">
                                        <li>Be specific</li>
                                        <li>In the deep tech fields</li>
                                        <li>Include a ‘?’ mark for questions </li>
                                    </ul>
                                </div>
                            </div>
                            <div className="inst-item">
                                <h1 className="inst-card-H">Input examples</h1>
                                <div className="inst-card-p">
                                    <ul className="inst-card-ul">
                                        <li>Buprenorphine use in adolescent opiate addiction</li>
                                        <li>Best practices for designing robust optical computing hardware</li>
                                        <li>How to create an efficient k-means clustering algorithm on a Riemannian manifold ? </li>
                                    </ul>
                                </div>
                            </div>
                            <div className="inst-item">
                                <h1 className="inst-card-H">Output accuracy</h1>
                                <div className="inst-card-p">
                                    Like any AI, there's a margin for error. While we achieve a solid 99% success rate (output very reasonable, logic and contextual related answers) in internal tests, we encourage you to double-check information
                                </div>
                            </div>
                        </div>
                    </div>
                    )}
                    <div className="textarea-container">
                    <div className="inquire-textbox" onClick={() => setShowModal(true)}>
                        <div className="inquire-button">
                            <span className="sign-in-text" onClick={() => setShowModal(true)}>Inquire</span>
                        </div>
                    </div>
                    </div>
                </div>
                <div className="answer-text-box">
                    <p className="answer-text">
                        Answer:
                    </p>
                </div>
            </div>
            <div className="support-frame-162">
                <div className="support-frame-126">
                    <p className="support-text-blue">Support Evidence</p>
                    <p className="mini-grey-text">(20 Items Found)</p>
                    <div className="dropdown-box">
                        <p className="inquire-text-1">
                            Top 10 Show
                        </p>
                    </div>
                    {/* <p className="support-text-blue">Sort By</p> */}
                    {/* <div className="dropdown-box">
                        <p className="inquire-text-1">
                            Year
                        </p>
                    </div> */}
                </div>
                <div className="support-frame-107">
                    <div className="support-frame-95a">
                        <div className="support-frame-135">
                            <p className="support-text">Evidence</p>
                        </div>
                        <div className="support-frame-94"> </div>
                        <div className="support-frame-135-b"></div>
                        <div className="support-frame-94"> </div>
                        <div className="support-frame-135-b"></div>
                        <div className="support-frame-94"> </div>
                        <div className="support-frame-135-b"></div>
                        
                    </div>
                    <div className="support-frame-95a">
                        <div className="support-frame-135">
                            <p className="support-text">Reference </p>
                        </div>
                        <div className="support-frame-94"> </div>
                        <div className="support-frame-135-b"></div>
                        <div className="support-frame-94"> </div>
                        <div className="support-frame-135-b"></div>
                        <div className="support-frame-94"> </div>
                        <div className="support-frame-135-b"></div>
                    </div>
                    {/* <div className="support-frame-95a">
                        <div className="support-frame-135">
                            <p className="support-text">Short Summary</p>
                        </div>
                        <div className="support-frame-94"> </div>
                        <div className="support-frame-135-b"></div>
                        <div className="support-frame-94"> </div>
                        <div className="support-frame-135-b"></div>
                        <div className="support-frame-94"> </div>
                        <div className="support-frame-135-b"></div>
                    </div> */}
                    <div className="support-frame-95a">
                        <div className="support-frame-135">
                            <p className="support-text">Relevance</p>
                        </div>
                        <div className="support-frame-94"> </div>
                        <div className="support-frame-135-b"></div>
                        <div className="support-frame-94"> </div>
                        <div className="support-frame-135-b"></div>
                        <div className="support-frame-94"> </div>
                        <div className="support-frame-135-b"></div>
                    </div>
                </div>
            </div>
            
          </div>
          <div className="comment">
            <div className="comment-container">
                <div className="comment-text">Leave a comment</div>
            </div>
            <div className="message-frame">
                <div className="message-header">Message *</div>
                <textarea 
                    className="message-box" 
                    value={message}
                    onChange={(e) => setMessage(e.target.value)}
                    placeholder="Type your message here..."
                />
            </div>
            <div className={buttonColor} onClick={handleSubmit}>
                <div className="feature-text-active">{buttonText}</div>
            </div>
            <div className="contact-text-combined">
                Or reach out to us at  
                <a href="mailto:contact@tylo.ai" className="contact-email"> contact@tylo.ai</a>
            </div>

          </div>
          <div className="all-container">
                <div className="all-rights-text">2024 Tylo AI Ltd. All rights reserved. </div>   
                <div className="all-rights-highlight" onClick={handleTOUClick}>
                    Terms of use  
                </div>
                <div className="all-rights-highlight" onClick={handlePrivacyPolicyClick}>
                    Privacy Policy 
                </div> 
                <a href="https://www.linkedin.com/company/tyloai/" target="_blank" rel="noopener noreferrer">
                <svg width="39" height="39" viewBox="0 0 39 39" fill="none" xmlns="https://www.w3.org/2000/svg">
  <g clip-path="url(#clip0_2801_871)">
    <path d="M29.4609 0.75H9.53906C4.685 0.75 0.75 4.685 0.75 9.53906V29.4609C0.75 34.315 4.685 38.25 9.53906 38.25H29.4609C34.315 38.25 38.25 34.315 38.25 29.4609V9.53906C38.25 4.685 34.315 0.75 29.4609 0.75Z" fill="white"/>
    <path d="M29.4609 0.75H9.53906C4.685 0.75 0.75 4.685 0.75 9.53906V29.4609C0.75 34.315 4.685 38.25 9.53906 38.25H29.4609C34.315 38.25 38.25 34.315 38.25 29.4609V9.53906C38.25 4.685 34.315 0.75 29.4609 0.75Z" fill="#4476F1"/>
    <path d="M27.8079 32.6375H32.0955C32.2508 32.6375 32.3998 32.5757 32.5097 32.4659C32.6196 32.356 32.6814 32.207 32.6814 32.0517L32.6836 22.9928C32.6836 18.258 31.6633 14.6186 26.1302 14.6186C24.0268 14.5403 22.0433 15.6246 20.9729 17.4352C20.9677 17.444 20.9597 17.4508 20.9502 17.4546C20.9407 17.4585 20.9303 17.4591 20.9204 17.4564C20.9105 17.4538 20.9018 17.4479 20.8955 17.4398C20.8893 17.4317 20.8858 17.4218 20.8857 17.4116V15.6416C20.8857 15.4862 20.824 15.3372 20.7141 15.2273C20.6042 15.1174 20.4552 15.0557 20.2998 15.0557H16.2309C16.0755 15.0557 15.9265 15.1174 15.8166 15.2273C15.7067 15.3372 15.645 15.4862 15.645 15.6416V32.0508C15.645 32.2062 15.7067 32.3552 15.8166 32.4651C15.9265 32.575 16.0755 32.6367 16.2309 32.6367H20.5182C20.6736 32.6367 20.8226 32.575 20.9325 32.4651C21.0424 32.3552 21.1042 32.2062 21.1042 32.0508V23.9395C21.1042 21.646 21.5392 19.4249 24.3826 19.4249C27.1856 19.4249 27.2219 22.0493 27.2219 24.088V32.0515C27.2219 32.2069 27.2837 32.3559 27.3935 32.4658C27.5034 32.5757 27.6525 32.6375 27.8079 32.6375ZM6.31641 9.48457C6.31641 11.2225 7.74712 12.6524 9.48516 12.6524C11.2228 12.6523 12.6526 11.2214 12.6526 9.48384C12.6523 7.74624 11.2223 6.31641 9.48457 6.31641C7.74639 6.31641 6.31641 7.74668 6.31641 9.48457ZM7.33579 32.6375H11.6288C11.7842 32.6375 11.9332 32.5757 12.0431 32.4658C12.153 32.3559 12.2147 32.2069 12.2147 32.0515V15.6416C12.2147 15.4862 12.153 15.3372 12.0431 15.2273C11.9332 15.1174 11.7842 15.0557 11.6288 15.0557H7.33579C7.18039 15.0557 7.03136 15.1174 6.92147 15.2273C6.81159 15.3372 6.74985 15.4862 6.74985 15.6416V32.0515C6.74985 32.2069 6.81159 32.3559 6.92147 32.4658C7.03136 32.5757 7.18039 32.6375 7.33579 32.6375Z" fill="white"/>
  </g>
  <defs>
    <clipPath id="clip0_2801_871">
      <rect width="37.5" height="37.5" fill="white" transform="translate(0.75 0.75)"/>
    </clipPath>
  </defs>
</svg>
</a>
            </div>
        </div>
    );
}

export default Features;