import React, { useEffect, useState} from "react";
// import ReactHtmlParser from 'html-react-parser'; 
// import ReactDOM from "react-dom";
import Tylo_icon from "../../assets/images/Tylo_logo.svg";
// import Tracker_close from "../../assets/images/tracker-close.svg";
// import Dropdown_open from "../../assets/images/Dropdown_open.svg" ;
// import Dropdown_closed from "../../assets/images/Dropdown_closed.svg";
import "../features/features.scss";
import { gapi } from "gapi-script";
import axios from "axios";
import {useNavigate } from "react-router-dom";
import useQuery from '../../hooks/useQuery';
import parse from 'html-react-parser';
// At the top of your index.js or index.tsx file
// import { setToken } from "../../services/userService";
// import { createClient } from '@supabase/supabase-js';

function Inquire() {
    console.log('Inquire unlocked component rendering')
    const [answerText, setAnswerText] = useState('');
    const googleProfileImageUrl = localStorage.getItem("googleProfileImageUrl");
    const navigate = useNavigate();
    
    const [supportEvidence, setSupportEvidence] = useState([]);
    const [referenceSources, setReferenceSources] = useState([]);
    const [relevanceDisplay, setRelevanceDisplay] = useState([]);
    // const [jsonData, setJsonData] = useState([]);
    const [itemCount, setItemCount] = useState([]);
    const [isDyslexiaFontEnabled, setIsDyslexiaFontEnabled] = useState(false);
    const [isRequesting, setIsRequesting] = useState(false);
    const [progress, setProgress] = useState(0);
    const [buttonText, setButtonText] = useState('Submit');
    const [buttonColor, setButtonColor] = useState('submit-button');
    // const [showModal, setShowModal] = useState(false);
    const [message, setMessage] = useState('');
    const token = localStorage.getItem('token');
    // const email = localStorage.getItem('tyloEmail');
    const [credits, setCredits] = useState('');
    // const [referralCode, setReferralCode] = useState('');
    const query = useQuery();

    const [showInstructions, setShowInstructions] = useState(false);
    const [tableOfContents, setTableOfContents] = useState([]);
    const [isTocOpen, setIsTocOpen] = useState(false);
    // let progressInterval;


    // Function to handle click event
    const navigateToFeatures = () => {
        navigate('/track'); // Navigate to /#features
    };
    const navigateToSettings = () => {
        navigate('/settings'); // Navigate to /#features
    };
    const toggleToc = () => {
        setIsTocOpen(!isTocOpen);
      };
    

    const [selectedOption1, setSelectedOption1] = useState('General Inquiry alt');
    // const [currentBody1, setCurrentBody1] = useState('');

    /*********  Tracker stuff ********************/
    // const [researchHeader, setResearchHeader] = useState('');
    // const [researchBody, setResearchBody] = useState('');
    // const [patentHeader, setPatentHeader] = useState('');
    // const [patentBody, setPatentBody] = useState('');
    // const [useCaseHeader, setUseCaseHeader] = useState('');
    // const [useCaseBody, setUseCaseBody] = useState('');
    // const [researcherHeader, setResearcherHeader] = useState('');
    // const [researcherBody, setResearcherBody] = useState('');
    // const [organizationHeader, setOrganizationHeader] = useState('');
    // const [organizationBody, setOrganizationBody] = useState('');
    // const [newsHeader, setNewsHeader] = useState('');
    // const [newsBody, setNewsBody] = useState('');

    // const [showMore, setShowMore] = useState(false);

    // const [trackTitleResearch, setTrackTitleResearch] = useState('Title Here');
    // const [bodyResearch, setBodyResearch] = useState('Body Here');
    // const [titleUseCase, setTitleUseCase] = useState('Title here');
    // const [bodyUseCase, setBodyUseCase] = useState('Body here');
    // const [titleNews,setTitleNews] = useState('Title Here');
    // const [bodyNews, setBodyNews] = useState('Body Here');
    // const [titleResearcher, setTitleResearcher] = useState('Title here');
    // const [bodyResearcher, setBodyResearcher] = useState('Body here');

    // const supabase = createClient('https://cyurqurlcxlyihpxzxyk.supabase.co', 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6ImN5dXJxdXJsY3hseWlocHh6eHlrIiwicm9sZSI6ImFub24iLCJpYXQiOjE2ODgxNDEwNDQsImV4cCI6MjAwMzcxNzA0NH0.CdUPlN6gZQ6aA4kFiZuBXoAc4W_zXj4ywH0oaDAV70o')



    
    // const updateCreditsDisplay = (credits) => {
    //     document.getElementById("creditDisplay").innerText = `Credits: ${credits}`;
    // }
    
    // const deductCredits = (amount) => {
    //     let currentCredits = credits;
    //     currentCredits = parseFloat((currentCredits - amount).toFixed(2)); 
    //     // localStorage.setItem("tyloCredits", currentCredits.toString());
    //     updateCreditsDisplay(currentCredits);
    //     updateBackendCredits(currentCredits);
    //     // console.log(credits)
    // }

    // const updateBackendCredits = async (newCredits) => {
    //     try {
    //         const token = localStorage.getItem('token'); // Assuming you're storing the token in localStorage
    //         const response = await axios.post("https://backend-dot-tylo-project.nw.r.appspot.com/api/v1/updateCredits", {
    //             credits: newCredits, // Sending the new credits amount to the backend
    //         }, {
    //             headers: {
    //                 'Authorization': `Bearer ${token}`, // Use the JWT token from localStorage
    //             }
    //         });
    
    //         // console.log("Response data:", response.data);
    
    //         // Update your frontend state to reflect the new credits
    //         setCredits(response.data.credits);
    //         // Optionally update local storage or other caches if necessary
    //     } catch (error) {
    //         console.error('Error updating credits:', error);
    //         // Handle error (show feedback to the user, etc.)
    //         // If you want to specifically handle HTTP error statuses, you can check error.response.status
    //     }
    // };
    

    


    useEffect(() => {
        const refCode = query.get('ref');
        if (refCode) {
            // setReferralCode(refCode);
            // console.log('refCode', refCode)
            axios.post('https://backend-dot-tylo-project.nw.r.appspot.com/api/v1/generateReferral', {
                referralCode: refCode,
            }, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                },
            })
            .then((response) => {
                console.log('Referral user details:', response.data);
                // You can use the referral user details if needed
            })
            .catch((error) => {
                console.error('Error fetching referral details:', error);
            });
        }

        // Load the Google API client library
        const script = document.createElement("script");
        script.src = "https://apis.google.com/js/api.js";
        script.onload = () => {
          // Initialize the Google API client library
          gapi.load("auth2", () => {
            gapi.auth2.init({
              client_id:
                "584832623015-02ioa5kbjqp9agd30pdiifln0bhb5trb.apps.googleusercontent.com",
              scope: "profile email",
              redirect_uri: "https://tylo.ai/", 
              cookiepolicy: "single_host_origin",
            });
          });
        };
        document.body.appendChild(script);

        fetch("https://backend-dot-tylo-project.nw.r.appspot.com/api/v1/profile", {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${token}`,
            },
          })
          .then(response => response.json())
          .then(data => {
            setCredits(data.credits);
            // console.log('credits backend:', data.credits)
        })
        .catch(error => console.error('Error fetching user data:', error));
        
        if (answerText) {
            const parser = new DOMParser();
            const doc = parser.parseFromString(answerText, 'text/html');
            const headers = doc.querySelectorAll('h1, h2, h3');
            const toc = Array.from(headers).map((header, index) => ({
              id: `toc-${index}`,
              text: header.textContent,
              level: parseInt(header.tagName.charAt(1)),
            }));
            setTableOfContents(toc);
          }
        
    }, [token, query, answerText]);

    const handlePrivacyPolicyClick = () => {
        window.open(`${window.location.origin}/#privacy`, '_blank');
    };
    const handleTOUClick = () => {
        window.open(`${window.location.origin}/#termsofuse`, '_blank');
    }
    

      const handleSignOutClick = () => {
        // Sign out from your application
        // This might involve removing the user's session or token from local storage
        localStorage.removeItem('token'); // For example, if you store a session token

        // If using Google sign-in and you want to sign the user out of Google as well
        const auth2 = gapi.auth2.getAuthInstance();
        if (auth2 != null) {
            auth2.signOut().then(auth2.disconnect().then(() => {
                console.log('User signed out of Google.');
                navigate('/#');
            }));
        }

        navigate('/#'); // -1 takes you one page back in the history
        // Redirect the user to the sign-in page or another appropriate page
        // This depends on your routing setup, for example using react-router
        // navigate('/signin'); // Example using react-router
    };

    const getAppState = () => {
        var textareaValue = document.querySelector('.inquire-textbox').value;
        const userId = localStorage.getItem('tyloEmail'); 
        return {
            userId,
            textareaValue,
            answerText,
          // Add other state elements as needed
        };
      };

    const handleSubmit = () => {
        const token = localStorage.getItem('token'); // Ensure you're getting the auth token correctly
        const appState = getAppState(); 
        

        setButtonText('Submitted');
        setButtonColor('submit-button-green');
    
        // Replace the URL with your actual endpoint
        const url = "https://backend-dot-tylo-project.nw.r.appspot.com/api/v1/message";
    
        axios.post(url, {
          message,
          appState: JSON.stringify(appState),
        }, {
          headers: {
            "Authorization": `Bearer ${token}`, // Adjust this according to your token auth setup
            "Content-Type": "application/json",
          },
        }).then(response => {
        //   console.log('Message submitted:', response.data);
          setMessage(''); // Clear the message input after successful submission
        }).catch(error => {
          console.error('Error submitting message:', error);
        });

        setTimeout(() => {
            setButtonText('Submit');
            setButtonColor('submit-button'); // Revert to the initial class
        }, 3000);
      };

    function formatUrlDisplayName(url) {
        try {
            const newUrl = new URL(url);
            // Check if the URL's hostname starts with 'www.' or if it's a typical URL with 'https://'
            if (newUrl.hostname.startsWith('www.')) {
                return newUrl.hostname.substring(4); // Remove 'www.' from the start
            } else if (newUrl.protocol === "https:") {
                return newUrl.hostname; // Use the full hostname
            } else {
                return url; // Return the original URL if it's not standard
            }
        } catch (error) {
            return url; // Return the original URL in case of an exception (invalid URL)
        }
    }
    

    const handleFilterChange = (event) => {
        // Get the selected value
        const selectedFilter = event.target.value;
    
        // Call the function to filter the data
        filterData(selectedFilter);
    };

    const filterData = (filterCriteria) => {
        let filteredData = [];

        const sortedData = itemCount.sort((a, b) => b.relevance - a.relevance);


    
        switch (filterCriteria) {
            case 'all':
                filteredData = sortedData.slice(0, itemCount.length); // Assuming 'data' is your original data array
                break;
            case 'top10':
                filteredData = sortedData.slice(0, 10); // Get the first 10 items
                break;
            case 'top20':
                filteredData = sortedData.slice(0, 20); // Get the first 20 items
                break;
            default:
                filteredData = sortedData.slice(0, 10); // Default to showing all
        }
    
        updateDisplay(filteredData);
        
    };


    const handleChange = (event) => {
        setSelectedOption1(event.target.value);  // Just update the state here
    };

    const handleButtonClick = () => {
        handleInquireClick(selectedOption1)

    };


    
    const handleDyslexiaFontToggle = (event) => {
        setIsDyslexiaFontEnabled(event.target.checked);
    };

    const updateDisplay = (filteredData) => {
        // Your logic to update the UI
        // This might involve clearing the existing displayed data
        // and then iterating over 'filteredData' to display each item

        // Clear existing data
        setSupportEvidence([]);
        setReferenceSources([]);

        // const minRelevance = Math.min(...filteredData.map(item => item.relevance));
        //         const maxRelevance = Math.max(...filteredData.map(item => item.relevance));


        // Function to calculate color based on relevance
        const getColorForRelevance = (relevance) => {
            if (relevance >= 0.55) {
                return "rgb(0, 225, 0)"; // Green
            } else if (relevance >= 0.5 && relevance < 0.55) {
                return "rgb(255, 225, 0)"; // Yellow
            } else {
                return "rgb(255, 0, 0)"; // Red
            }
        };

        let supportData = [];
        let referenceData = [];
        let relevanceDisplay = [];

        filteredData.forEach((item, index) => {
            let className = index % 2 === 0 ? 'support-frame-94' : 'support-frame-135-b';
            const color = getColorForRelevance(item.relevance || item.score);
            const relevanceStyle = {
                backgroundColor: color
            };
                    
            supportData.push({ content: item.paragraph || item.content, className });
            
            // const linkDisplayName = formatUrlDisplayName(item.article_url);
            
            referenceData.push({ title: item.title, url: item.article_url || item.url, className });
            
            relevanceDisplay.push({ style: relevanceStyle, className });
        });

        setSupportEvidence(supportData);
        setReferenceSources(referenceData);
        setRelevanceDisplay(relevanceDisplay);
    };

    const updateStatusText = () => {
        const messages = [
            "Initiating data gathering protocols...",
            "Compiling raw data from diverse sources...",
            "Analyzing information through advanced algorithms...",
            "Synthesizing insights from complex datasets...",
            "Applying machine learning techniques for deeper analysis...",
            "Cross-referencing findings against existing knowledge bases...",
            "Finalizing comprehensive results presentation...",
            "Almost there, preparing to display findings..."
        ];
        let counter = 0;
    
        const messageInterval = setInterval(() => {
            setAnswerText(messages[counter]);
            counter++;
            if (counter === messages.length) {
                clearInterval(messageInterval);
            }
        }, 4000); // Update text every 2 seconds
    
        return messageInterval;
    };
    
    

    const handleInquireClick = async (prompt) => {
        setIsRequesting(true);
        
        const statusInterval = updateStatusText();
        setProgress(10);

        
        let progressInterval;
          // Simulate progress for the loading bar
        const incrementProgress = (increment) => {
            setProgress(prevProgress => {
                const nextProgress = prevProgress + increment;
                if (nextProgress >= 100) {
                    clearInterval(progressInterval); // Ensure clearing interval
                    return 100;
                }
                return nextProgress;
            });
        };
        progressInterval = setInterval(() => {
            incrementProgress(10); // Increment by 10 every 3 seconds.
            setProgress(progress => {
                if (progress >= 60) {
                    clearInterval(progressInterval); // Clear the interval once progress reaches 60.
                }
                return progress;
            });
        }, 3000);
        

        var textareaValue = document.querySelector('.inquire-textbox').value;

        // Add conditional logic for Report option
        if (prompt === "Report") {
            try {
                const response = await fetch('https://backend-dot-tylo-project.nw.r.appspot.com/api/v1/report', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${token}`,
                    },
                    body: JSON.stringify({ question: textareaValue }),
                });

                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }

                const data = await response.json();
                console.log('Report Response Data:', data);

                // Combine all sections into a single formatted string
                const formattedContent = data.sections.map((section, index) => {
                    return `<h2>${section.title}</h2>\n${section.content}`;
                }).join('\n\n');

                setAnswerText(formattedContent);
                setItemCount(data.supporting_evidence);
                updateDisplay(data.supporting_evidence);
            } catch (error) {
                console.error('Error fetching report:', error);
                setAnswerText('Error generating report. Please try again.');
            } finally {
                clearInterval(statusInterval);
                setIsRequesting(false);
                clearInterval(progressInterval);
            }
            return;
        }

        const url = 'https://backend-dot-tylo-project.nw.r.appspot.com/api/v1/inquiry';

        const body = {
            question: textareaValue,
            
        };

        const promptType = prompt;

        const params = new URLSearchParams({ promptType });

        const response = await fetch(`${url}?${params.toString()}`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`, // If you have an authentication token
            },
            body: JSON.stringify(body),
        });


        if (!response.ok) {
            throw new Error('Network response was not ok');
        }

        const data = await response.json();
        console.log('Frontend Response Data:', data.message);
        console.log("token", token)
        setAnswerText(data.message)
        clearInterval(statusInterval);
        setIsRequesting(false);
        clearInterval(progressInterval);

        setItemCount(data.supporting_evidence)
        updateDisplay(data.supporting_evidence)
    
        return data;


    };
  
  

   

    /********************************************************************************************************* */
   

    const customStyles = {
        h1: {
          fontSize: '32px',    // Customize the size of <h1>
          margin: '0px 0',    // Adjust spacing
          marginBottom: '0px',
        },
        h2: {
          fontSize: '26px',    // Customize the size of <h2>
          margin: '0px 0',    // Adjust spacing
          marginBottom: '0px',
        },
        h3: {
            // fontSize: '26px',    // Customize the size of <h2>
            margin: '0px 0',    // Adjust spacing
          },
        p: {
          lineHeight: '1.3',   // Adjust line spacing for paragraphs
          margin: '0px 0',    // Customize margin between paragraphs
          marginBottom: '0px',
        },
        br: {
            lineHeight: '0.5',   // Reduce the space after <br> tags
            display: 'block',    // Ensure <br> behaves correctly as a block element
            marginBottom: '0px', // Adjust vertical space after <br>
          },
        ul: {
            margin: '10px 0',     // Adjust the margin above and below the list
            // paddingLeft: '20px',  // Indentation for the list
            // listStyleType: 'disc', // Bullet point style (you can change this to 'circle', 'square', etc.)
        },
        li: {
            marginBottom: '2px',  // Adjust the space between list items
            lineHeight: '1.4',    // Line height for list items
         },
      };


    console.log('Inquire component about to render JSX');
    return (
        <div className="feature-page">
            
                <div className="feature-page-icon">
                    <img src={Tylo_icon} alt="Tylo Icon" className="tylo-icon" />
                    <div className="tabs">
                        <div className="top-bar-tab-current_page">
                            <a href="/#features" className="top-bar-text">Features</a> {/* Link to the Features page */}
                        </div>
                        <div className="top-bar-tab">
                            <a href="/#about" className="top-bar-text">About Us</a> {/* Link to the About page */}
                        </div>
                    </div>
                    <img src={googleProfileImageUrl} alt="Profile Icon" onClick={navigateToSettings} className="profile-icon" />
                    <div className="login-button" onClick={handleSignOutClick}>
                        <span className="login-text" >Sign Out</span>
                    </div>
                </div>

          <div className="gradient-rectangle">
            <h2 className="feature-title">Deeptech innovation Assistant</h2>
            
            <div className="tab-horizontal-container">
                <div className="sign-in" >
                    <span className="sign-in-text" >Inquire</span>
                </div>
                <div className="sign-in-disabled" onClick={navigateToFeatures}>
                    <div  className="sign-in-text-disabled" >Track</div>
                </div>
            </div>
          </div>
          <div className={`feature-page-2 ${isTocOpen ? 'sidebar-open' : ''}`}>
            <button className="toc-toggle" onClick={toggleToc}>
                    {isTocOpen ? '×' : '☰'}
                </button>
                <div className={`toc-sidebar ${isTocOpen ? 'open' : ''}`}>
                    <div className="table-of-contents">
                    <h2>Table of Contents</h2>
                    <ul>
                        {tableOfContents.map((item) => (
                        <li key={item.id} className={`toc-level-${item.level}`}>
                            <a href={`#${item.id}`}>{item.text}</a>
                        </li>
                        ))}
                    </ul>
                    </div>
                </div>
            <div className="horizontal-content-wrapper">
                <div className="Inquire_feature-frame-108">
                    <h2 className="inquire-header">Inquire Anything</h2>
                    <div className="inquire-frame-95">
                        <div className="helper-container">
                            <h2 className="inquire-text-1">
                                The starting point of your evidence-based innovation.
                            </h2>
                            <div className="instructions-container">
                                <div className="instructions">
                                    Instructions 
                                </div>
                                <div className="instructions-toggle dropdown-button" onClick={(e) => { e.stopPropagation(); setShowInstructions(!showInstructions); }}>
                                        {showInstructions ?  '▲' : '▼'}
                                </div>
                            </div>
                        </div>
                        {showInstructions && (
                        <div className="instruction-card">
                            <div className="inst-top-col">
                                <div className="inst-item">
                                    <h1 className="inst-card-H">What is it for?</h1>
                                    <div className="inst-card-p">
                                        <ul className="inst-card-ul">
                                            <li>Get an answer to deeptech questions</li>
                                            <li>Learn innovative technology topics or concepts</li>
                                            <li>Find evidence for hypothesis & assumptions</li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="inst-item">
                                    <h1 className="inst-card-H">What is it not a good fit for?</h1>
                                    <div className="inst-card-p">
                                    Short questions or general topics may not be your best bet. Anything that cannot find clues from science publications and patent ocean.
                                    </div>
                                </div>
                                <div className="inst-item">
                                    <h1 className="inst-card-H">Response time:</h1>
                                    <div className="inst-card-p">
                                        Typically 30 to 60 seconds
                                    </div>
                                </div>
                            </div>
                            <div className="inst-top-col">
                                <div className="inst-item">
                                    <h1 className="inst-card-H">Optimise your input:</h1>
                                    <div className="inst-card-p">
                                        <ul className="inst-card-ul">
                                            <li>Be specific</li>
                                            <li>In the deep tech fields</li>
                                            <li>Include a '?' mark for questions </li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="inst-item">
                                    <h1 className="inst-card-H">Input examples:</h1>
                                    <div className="inst-card-p">
                                        <ul className="inst-card-ul">
                                            <li>Buprenorphine use in adolescent opiate addiction</li>
                                            <li>Best practices for designing robust optical computing hardware</li>
                                            <li>How to create an efficient k-means clustering algorithm on a Riemannian manifold? </li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="inst-item">
                                    <h1 className="inst-card-H">Output accuracy:</h1>
                                    <div className="inst-card-p">
                                    Like any AI, there's a margin for error. While we achieve a solid 99% success rate (output reasonable, logic and contextual related answers) via internal tests, we encourage you to double-check any information obtained.
                                    </div>
                                </div>
                            </div>
                        </div>
                        )}
                        <div className="info" id="creditDisplay">Credits: {credits} </div>
                        <div className="tracker-142">
                        <div className="info">Tell us a bit more about what you are looking for</div>
                                <select 
                                    className="tracker-96"
                                    value={selectedOption1}
                                    onChange={handleChange}
                                >
                                    <option value="General Inquiry alt">General Inquiry</option>
                                    <option value="Key findings alt">Analyse key research findings</option>
                                    <option value="SWOT alt">SWOT analysis</option>
                                    <option value="Evidenced based opinion alt">Find evidence based opinion</option>
                                    <option value="Patent alt">Advanced patent analysis</option>
                                    <option value="Report">Full Report</option>
                                </select>
                            </div>
                        <div className="textarea-container">
                            <textarea className="inquire-textbox" placeholder="Enter your inquiry here"></textarea>
                                <div
                                    className={`inquire-button_unlocked ${isRequesting ? 'inquire-button_disabled' : ''}`}
                                    onClick={!isRequesting ? handleButtonClick : undefined}
                                >
                                    <span className="sign-in-text">{isRequesting ? 'Processing...' : 'Inquire'}</span>
                                </div>
                        </div>    
                    </div>
                    {isRequesting && (
                        <div className="loader-bar-container">
                        <div className="loader-bar" style={{ width: `${progress}%` }}></div>
                        </div>
                    )}
                    
                    <div className="answer-text-box"
                    style={{
                        ...isDyslexiaFontEnabled && { backgroundColor: '#FFFFE0' }, // Only apply this style if isDyslexiaFontEnabled is true
                        height: answerText ? '650px' : '316px'
                    }}
                    >
                        <p className="answer-header">Answer:</p>
                        <p className="answer-text"
                        style={{
                            ...isDyslexiaFontEnabled && { fontFamily: '"OpenDyslexic", sans-serif' }, // Only apply this style if isDyslexiaFontEnabled is true
                        }}
                        >
                                {parse(answerText, {
                                    replace: (domNode) => {
                                    if (domNode.name === 'h1') {
                                        return <h1 style={customStyles.h1}>{domNode.children[0].data}</h1>;
                                    }
                                    if (domNode.name === 'h2') {
                                        return <h2 style={customStyles.h2}>{domNode.children[0].data}</h2>;
                                    }
                                    if (domNode.name === 'h3') {
                                        return <h3 style={customStyles.h3}>{domNode.children[0].data}</h3>;
                                    }
                                    if (domNode.name === 'p') {
                                        return <p style={customStyles.p}>{domNode.children.map(child => {
                                            if (child.type === 'text') return child.data;
                                            if (child.name === 'span') {
                                                return <span style={{
                                                    // backgroundColor: child.attribs.style.split(';').find(s => s.includes('background-color')).split(':')[1].trim(),
                                                    color: child.attribs.style.split(';').find(s => s.includes('color')).split(':')[1].trim(),
                                                    padding: child.attribs.style.split(';').find(s => s.includes('padding')).split(':')[1].trim(),
                                                    borderRadius: child.attribs.style.split(';').find(s => s.includes('border-radius')).split(':')[1].trim()
                                                }}>{child.children[0].data}</span>;
                                            }
                                            return null;
                                        })}</p>;
                                    }
                                    if (domNode.name === 'br') {
                                        return <br style={customStyles.br} />;
                                    }
                                   
                                    }
                                })}
                        </p>
                    </div>
                </div>
            </div>
            
            
            
            <div className="support-frame-162">
                <div className="support-frame-126">
                    <p className="support-text-blue">Support Evidence</p>
                    <p className="mini-grey-text">({itemCount.length} Items Found)</p>
                    <div className="dropdown-box">
                        <select className="inquire-text-1" onChange={handleFilterChange}>
                            <option value="all">All</option>
                            <option value="top10">Top 10</option>
                            <option value="top20">Top 20</option>
                        </select>
                    </div>
                    {/* <p className="support-text-blue">Sort By</p>
                    <div className="dropdown-box">
                        <p className="inquire-text-1">
                            Year
                        </p>
                    </div> */}

                    <label>
                        <input
                            type="checkbox"
                            checked={isDyslexiaFontEnabled}
                            onChange={handleDyslexiaFontToggle}
                        />
                        Use Dyslexia-Friendly Font
                    </label>

                </div>
                <div className="support-frame-107">
                    <div className="support-frame-95a">
                        <div className="support-frame-135">
                            <p className="support-text">Support evidence</p>
                        </div>
                        {supportEvidence.map((item, index) => (
                            <div key={index} className={`support-text-content ${item.className}`}>
                                <span className="evidence-number">{index + 1}. </span>{item.content}
                            </div>
                        ))}    
                    </div>
                    <div className="support-frame-95b">
                        <div className="support-frame-135">
                            <p className="support-text">Reference & sources</p>
                        </div>
                        {referenceSources.map((item, index) => (
                            <div key={index} className={`support-text-content ${item.className}`}>
                                <a href={item.url} target="_blank" rel="noopener noreferrer">
                                    {item.title} - {formatUrlDisplayName(item.url)}
                                </a>
                            </div>
                        ))}
                    </div>
                    <div className="support-frame-95c">
                        <div className="support-frame-135">
                            <p className="support-text">Relevance </p>
                        </div>
                        {relevanceDisplay.map((item, index) => (
                            <div key={index} className={`${item.className} relevance-circle`} style={item.style}></div>
                        ))}
                      
                    </div>
                </div>
            </div>
            
          </div>
          <div className="comment">
            <div className="comment-container">
                <div className="comment-text">Leave a comment</div>
            </div>
            <div className="message-frame">
                <div className="message-header">Message *</div>
                <textarea 
                    className="message-box" 
                    value={message}
                    onChange={(e) => setMessage(e.target.value)}
                    placeholder="Type your message here..."
                />
            </div>
            <div className={buttonColor} onClick={handleSubmit}>
                <div className="feature-text-active">{buttonText}</div>
            </div>
            <div className="contact-text-combined">
                Or reach out to us at  
                <a href="mailto:contact@tylo.ai" className="contact-email"> contact@tylo.ai</a>
            </div>

          </div>
          <div className="all-container">
                <div className="all-rights-text">2024 Tylo AI Ltd. All rights reserved. </div>   
                <div className="all-rights-highlight" onClick={handleTOUClick}>
                    Terms of use  
                </div>
                <div className="all-rights-highlight" onClick={handlePrivacyPolicyClick}>
                    Privacy Policy 
                </div> 
                <a href="https://www.linkedin.com/company/tyloai/" target="_blank" rel="noopener noreferrer">
                <svg width="39" height="39" viewBox="0 0 39 39" fill="none" xmlns="https://www.w3.org/2000/svg">
  <g clip-path="url(#clip0_2801_871)">
    <path d="M29.4609 0.75H9.53906C4.685 0.75 0.75 4.685 0.75 9.53906V29.4609C0.75 34.315 4.685 38.25 9.53906 38.25H29.4609C34.315 38.25 38.25 34.315 38.25 29.4609V9.53906C38.25 4.685 34.315 0.75 29.4609 0.75Z" fill="white"/>
    <path d="M29.4609 0.75H9.53906C4.685 0.75 0.75 4.685 0.75 9.53906V29.4609C0.75 34.315 4.685 38.25 9.53906 38.25H29.4609C34.315 38.25 38.25 34.315 38.25 29.4609V9.53906C38.25 4.685 34.315 0.75 29.4609 0.75Z" fill="#4476F1"/>
    <path d="M27.8079 32.6375H32.0955C32.2508 32.6375 32.3998 32.5757 32.5097 32.4659C32.6196 32.356 32.6814 32.207 32.6814 32.0517L32.6836 22.9928C32.6836 18.258 31.6633 14.6186 26.1302 14.6186C24.0268 14.5403 22.0433 15.6246 20.9729 17.4352C20.9677 17.444 20.9597 17.4508 20.9502 17.4546C20.9407 17.4585 20.9303 17.4591 20.9204 17.4564C20.9105 17.4538 20.9018 17.4479 20.8955 17.4398C20.8893 17.4317 20.8858 17.4218 20.8857 17.4116V15.6416C20.8857 15.4862 20.824 15.3372 20.7141 15.2273C20.6042 15.1174 20.4552 15.0557 20.2998 15.0557H16.2309C16.0755 15.0557 15.9265 15.1174 15.8166 15.2273C15.7067 15.3372 15.645 15.4862 15.645 15.6416V32.0508C15.645 32.2062 15.7067 32.3552 15.8166 32.4651C15.9265 32.575 16.0755 32.6367 16.2309 32.6367H20.5182C20.6736 32.6367 20.8226 32.575 20.9325 32.4651C21.0424 32.3552 21.1042 32.2062 21.1042 32.0508V23.9395C21.1042 21.646 21.5392 19.4249 24.3826 19.4249C27.1856 19.4249 27.2219 22.0493 27.2219 24.088V32.0515C27.2219 32.2069 27.2837 32.3559 27.3935 32.4658C27.5034 32.5757 27.6525 32.6375 27.8079 32.6375ZM6.31641 9.48457C6.31641 11.2225 7.74712 12.6524 9.48516 12.6524C11.2228 12.6523 12.6526 11.2214 12.6526 9.48384C12.6523 7.74624 11.2223 6.31641 9.48457 6.31641C7.74639 6.31641 6.31641 7.74668 6.31641 9.48457ZM7.33579 32.6375H11.6288C11.7842 32.6375 11.9332 32.5757 12.0431 32.4658C12.153 32.3559 12.2147 32.2069 12.2147 32.0515V15.6416C12.2147 15.4862 12.153 15.3372 12.0431 15.2273C11.9332 15.1174 11.7842 15.0557 11.6288 15.0557H7.33579C7.18039 15.0557 7.03136 15.1174 6.92147 15.2273C6.81159 15.3372 6.74985 15.4862 6.74985 15.6416V32.0515C6.74985 32.2069 6.81159 32.3559 6.92147 32.4658C7.03136 32.5757 7.18039 32.6375 7.33579 32.6375Z" fill="white"/>
  </g>
  <defs>
    <clipPath id="clip0_2801_871">
      <rect width="37.5" height="37.5" fill="white" transform="translate(0.75 0.75)"/>
    </clipPath>
  </defs>
</svg>
</a>
            </div>
        
        </div>
    );
}

export default Inquire;