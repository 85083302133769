// import React, { useEffect, useState } from "react";
// import ReactHtmlParser from 'html-react-parser'; 
// import ReactDOM from "react-dom";
import Tylo_icon from "../../assets/images/Tylo_logo.svg";
// import Tracker_close from "../../assets/images/tracker-close.svg";
// import Dropdown_open from "../../assets/images/Dropdown_open.svg" ;
// import Dropdown_closed from "../../assets/images/Dropdown_closed.svg";
import "../footer/footer.scss";
// import { gapi } from "gapi-script";
// import axios from "axios";
// import {useNavigate } from "react-router-dom";
// import { setToken } from "../../services/userService";
// import { createClient } from '@supabase/supabase-js';


function Tou () {

    // const navigate = useNavigate();

    const handlePrivacyPolicyClick = () => {
        window.open(`${window.location.origin}/#privacy`, '_blank');
    };
    const handleTOUClick = () => {
        window.open(`${window.location.origin}/#termsofuse`, '_blank');
    }

    return (
        <div className="feature-page">
            <div className="feature-page-icon">
                <img src={Tylo_icon} alt="Tylo Icon" className="tylo-icon" />
                <div className="tabs">
                    <div className="top-bar-tab-current_page">
                        <a href="/#features" className="top-bar-text">Features</a> {/* Link to the Features page */}
                    </div>
                    <div className="top-bar-tab">
                        <a href="/#about" className="top-bar-text">About Us</a> {/* Link to the About page */}
                    </div>
                </div>
                {/* <img src={googleProfileImageUrl} alt="Profile Icon" className="profile-icon" /> */}
            </div>
            <div className="gradient-rectangle">
                <h2 className="feature-title">Terms of Use</h2>
                <p className="privacy-update">update Date: 15 November 2023</p>
            </div>
            <div className="privacy-page">
                <div className="privacy-frame-32">
                    <h2 className="privacy-main-header">Welcome</h2>
                    <p className="privacy-main-text">
                        Whether you an individual user, an academic institution or an organisation, these Terms of Use (“Terms”) apply to you. Please read and agree to them as they represent the conditions of your use of the tylo.ai website and associated research insight services (“Tylo.ai”). These Terms form a binding agreement between you and Tylo AI Ltd (“we”, “us” or “our”). By using Tylo.ai, you agree to these Terms in their entirety. If you are unable to agree with these Terms, please do not create an account or use Tylo.ai.
                    </p>
                    <div className="grey-tab">
                        <p className="privacy-main-bold">
                            If you are using Tylo.ai as an organisation representative, you represent, warrant and confirm to us that you have the authority to bound that business or organisation to these Terms.
                        </p>
                    </div>
                </div>
                <div className="privacy-frame-32">
                    <h2 className="privacy-main-header">About Us</h2>
                    <p className="privacy-main-text">
                        We are Tylo AI Ltd, a company registered in the United Kingdom (see below for company information). If you have any concerns, issues, requests, complaints or enquiries regarding these Terms or Tylo.ai, please use the information below to contact us:
                    </p>
                    <div className="grey-container">
                        <div className="grey-tab">
                            <h2 className="privacy-sub-header">Tylo AI</h2>
                        </div>
                        <div className="grey-tab">
                            <h2 className="privacy-sub-header">Reg No:</h2><p className="privacy-main-bold">14734333</p>
                        </div>
                        <div className="grey-tab">
                            <h2 className="privacy-sub-header">Email:</h2><p className="privacy-main-bold">contact@tylo.ai</p>
                        </div>
                        <div className="grey-tab">
                            <h2 className="privacy-sub-header">Address:</h2><p className="privacy-main-bold">7 Bell Yard, London, England, WC2A 2JR, United Kingdom</p>
                        </div>
                    </div>  
                </div>
                <div className="privacy-frame-32">
                    <h2 className="privacy-main-header">Tylo.ai Services</h2>
                    <p className="privacy-main-text">
                        Tylo.ai is provided as a research-focused Artificial Intelligence (AI) that extracts insights and provides answers to questions from research publications. The content provided by Tylo.ai are linked to existing publications and have not been curated or checked by us for reliability or accuracy. The content is provided “as is” – we are not the provider. Therefore, when using Tylo.ai, thread with caution and make further research. Tylo AI Ltd is not responsible for any inaccuracies or liabilities you may suffer from your reliance on the content or results from your use of Tylo.ai.
                    </p>
                    <p className="privacy-main-bold">Please bear in mind that:</p>
                    <div className="grey-container">
                        <div className="grey-tab-full">
                            <div className="circle-check">
                            <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="https://www.w3.org/2000/svg">
    <path d="M16.1078 0.901957C15.8102 0.736394 15.483 0.631107 15.1447 0.592116C14.8065 0.553125 14.4638 0.581194 14.1364 0.67472C13.809 0.768245 13.5033 0.925392 13.2367 1.13717C12.9701 1.34895 12.7478 1.61121 12.5826 1.90896L7.77052 10.5689L5.01649 7.81489C4.77739 7.56732 4.49137 7.36985 4.17514 7.23401C3.8589 7.09816 3.51877 7.02666 3.17461 7.02367C2.83044 7.02068 2.48912 7.08626 2.17057 7.21659C1.85202 7.34692 1.56262 7.53939 1.31924 7.78276C1.07587 8.02613 0.883407 8.31553 0.753078 8.63408C0.622748 8.95263 0.557166 9.29395 0.560156 9.63812C0.563147 9.98229 0.634651 10.3224 0.770497 10.6386C0.906342 10.9549 1.10381 11.2409 1.35137 11.48L6.53542 16.6641C7.02531 17.1552 7.68628 17.4248 8.36798 17.4248L8.72698 17.3989C9.12427 17.3433 9.50324 17.1963 9.83406 16.9694C10.1649 16.7425 10.4385 16.4419 10.6334 16.0912L17.1135 4.42711C17.2789 4.12961 17.3841 3.80244 17.4231 3.46428C17.4621 3.12611 17.4341 2.78358 17.3407 2.45623C17.2473 2.12889 17.0904 1.82315 16.8788 1.55646C16.6673 1.28978 16.4053 1.06738 16.1078 0.901957Z" fill="#4476F1"/>
    </svg>  
                            </div>
                                <p className="privacy-main-text"> The results generated may not always be accurate, complete or factual. You should not use Tylo.ai or solely rely on it as your primary and only source of information.</p>     
                        </div>
                        <div className="grey-tab-full">
                            <div className="circle-check">
                            <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="https://www.w3.org/2000/svg">
    <path d="M16.1078 0.901957C15.8102 0.736394 15.483 0.631107 15.1447 0.592116C14.8065 0.553125 14.4638 0.581194 14.1364 0.67472C13.809 0.768245 13.5033 0.925392 13.2367 1.13717C12.9701 1.34895 12.7478 1.61121 12.5826 1.90896L7.77052 10.5689L5.01649 7.81489C4.77739 7.56732 4.49137 7.36985 4.17514 7.23401C3.8589 7.09816 3.51877 7.02666 3.17461 7.02367C2.83044 7.02068 2.48912 7.08626 2.17057 7.21659C1.85202 7.34692 1.56262 7.53939 1.31924 7.78276C1.07587 8.02613 0.883407 8.31553 0.753078 8.63408C0.622748 8.95263 0.557166 9.29395 0.560156 9.63812C0.563147 9.98229 0.634651 10.3224 0.770497 10.6386C0.906342 10.9549 1.10381 11.2409 1.35137 11.48L6.53542 16.6641C7.02531 17.1552 7.68628 17.4248 8.36798 17.4248L8.72698 17.3989C9.12427 17.3433 9.50324 17.1963 9.83406 16.9694C10.1649 16.7425 10.4385 16.4419 10.6334 16.0912L17.1135 4.42711C17.2789 4.12961 17.3841 3.80244 17.4231 3.46428C17.4621 3.12611 17.4341 2.78358 17.3407 2.45623C17.2473 2.12889 17.0904 1.82315 16.8788 1.55646C16.6673 1.28978 16.4053 1.06738 16.1078 0.901957Z" fill="#4476F1"/>
    </svg>  
                            </div>
                                <p className="privacy-main-text"> Tylo.ai rely on existing works from databases and generates its answers based on these databases.</p>  
                        </div>
                    </div>
                </div>
                <div className="privacy-frame-32">
                    <h2 className="privacy-main-header">Usage Eligibility</h2>
                    <p className="privacy-main-text">
                        To use Tylo.ai, you must be at least 18 years of age (or the age of majority in your location) and have the legal capacity to enter into a binding agreement. In summary, your use of Tylo.ai must not be in violation of any applicable laws or regulations. You are responsible for ensuring that Tylo.ai does go against any applicable laws of your jurisdiction. By using Tylo.ai, you warrant that you meet the foregoing eligibility requirements.
                    </p>
                </div>
                <div className="privacy-frame-32">
                    <h2 className="privacy-main-header">Account and Access</h2>
                    <p className="privacy-main-text">
                        To enjoy certain Tylo.ai features, you must create an account and provide identifying data about you (and company). You must provide accurate, current and compete data and ensure you update your profile when the need arises. You are responsible for maintaining the confidentiality of your account credentials, and you agree not to disclose your password to any third party (except with respect to members in your organisation) without written permission from us. You are solely responsible for any activities or actions taken under your account. If you believe that any individual other than you or your authorised staff has access to your account, please notify us at contact@tylo.ai immediately.
                    </p>
                </div>
                <div className="privacy-frame-32">
                    <h2 className="privacy-main-header">Data Protection and Privacy</h2>
                    <p className="privacy-main-text">
                        We care about your privacy. Our data protection and practices related to privacy are described in our Privacy Policy. It describes the data we collect when you use Tylo.ai and how we use that data. By using Tylo.ai, you agree to our data protection and privacy practices.
                    </p>
                </div>
                <div className="privacy-frame-32">
                    <h2 className="privacy-main-header">Intellectual Property Ownership</h2>
                    <p className="privacy-main-text">
                        (Aside from the content generated by Tylo.ai from publications and databases) The content on Tylo.ai, including all images, videos, audio, features and functionality are our property and respective licensors and are subject to copyright in the United Kingdom and other locations.
                    </p>
                    <p className="privacy-main-text">
                        All trademarks, service marks, logos, graphics, icons and trade names displayed on Tylo.ai are the property of Tylo AI Ltd or third-party licensors. You are not permitted to use these marks without Tylo AI Ltd’s prior written consent or the respective owner. You may not use our trademarks, including with another product or service, in any manner that may cause confusion or disparage or discredits us.
                    </p>
                    <p className="privacy-main-text">
                        All our intellectual property, including copyrights, patent right and trademarks are protected in the UK and other locations. We reserve all rights not granted to you concerning our intellectual property.
                    </p>
                </div>
                <div className="privacy-frame-32">
                    <h2 className="privacy-main-header">Your License to Use Tylo.ai</h2>
                    <p className="privacy-main-text">
                        Subject to your compliance with these Terms, we hereby grant you a limited, non-exclusive, non-transferable, revocable license to access and use Tylo.ai for your personal or internal business purposes. This license does not grant you any ownership rights in Tylo.ai.
                    </p>
                </div>
                <div className="privacy-frame-32">
                    <h2 className="privacy-main-header">Prohibited Uses</h2>
                    <p className="privacy-main-bold">Concerning our intellectual property and your use of Tylo.ai, you shall NOT:</p>
                    <div className="grey-container">
                        <div className="grey-sub-full">
                            <div className="circle-check">01</div>
                                <p className="privacy-main-text">License, copy, distribute, publicly display or perform, publish, transmit, edit, adapt or create derivative works from any of our intellectual property.</p>     
                        </div>
                        <div className="grey-sub-full">
                            <div className="circle-check">02</div>
                                <p className="privacy-main-text">(Except for individuals within your organisation) use or share your account password or other credentials with a third party.</p>     
                        </div>
                        <div className="grey-sub-full">
                            <div className="circle-check">03</div>
                                <p className="privacy-main-text">Attempt to circumvent or disable any security features or measures implemented by us.</p>     
                        </div>
                    </div>
                    <div className="grey-container">
                        <div className="grey-sub-full">
                            <div className="circle-check">04</div>
                                <p className="privacy-main-text">Infringe on our intellectual property rights or those of others, including copyrights, trademarks, patent or trade secret rights.</p>     
                        </div>
                        <div className="grey-sub-full">
                            <div className="circle-check">05</div>
                                <p className="privacy-main-text">Interfere with Tylo.ai’s proper running, including introducing viruses, malware or other disruptive code.</p>     
                        </div>
                        <div className="grey-sub-full">
                            <div className="circle-check">06</div>
                                <p className="privacy-main-text">Use Tylo.ai in any location or transfer it to any location where the law prohibits its use.</p>     
                        </div>
                    </div>
                    <div className="grey-container">
                        <div className="grey-sub-full">
                            <div className="circle-check">07</div>
                                <p className="privacy-main-text">Decompile, reverse-engineer, disassemble or otherwise attempt to know the source code or programs that make up Tylo.ai.</p>     
                        </div>
                        <div className="grey-sub-full">
                            <div className="circle-check">08</div>
                                <p className="privacy-main-text">Copy, reproduce, modify, distribute or create derivative works based on Tylo.ai without explicit written consent from us.</p>     
                        </div>
                    </div>
                    <div className="grey-container">
                        <div className="grey-sub-full">
                            <div className="circle-check">09</div>
                                <p className="privacy-main-text">Attempt to access areas on Tylo.ai, networks or servers that are not intended for your use or attempt to bypass any measures implemented to restrict access.</p>     
                        </div>
                        <div className="grey-sub-full">
                            <div className="circle-check">10</div>
                                <p className="privacy-main-text">Engage in data mining, data scraping or any other unauthorised collection of data or information from Tylo.ai.</p>     
                        </div>
                    </div>
                </div>
                <div className="privacy-frame-32">
                    <h2 className="privacy-main-header">Feedback</h2>
                    <p className="privacy-main-text">
                        If you provide any feedback, suggestions or ideas regarding Tylo.ai ("Feedback"), you agree that we may use, modify or incorporate the Feedback into Tylo.ai or other products or services without any obligation or compensation to you.
                    </p>
                </div>
                <div className="privacy-frame-32">
                    <h2 className="privacy-main-header">Subscription, Payments and Refunds</h2>
                    <p className="privacy-main-text">
                        Tylo.ai offers a subscription model with its plan charged for specified durations. By subscribing to Tylo.ai, you agree to pay the applicable subscription fees as outlined on the payment page. Subscription fees are exclusive of any applicable taxes. You are responsible for paying any taxes imposed on your subscription payments.
                    </p>
                    <p className="privacy-main-text">
                        You agree to make payments through the supported payment methods (including credit/debit cards). By submitting any payment method, you warrant that the information is accurate, and you authorise Tylo AI Ltd through its third-party payment processor(s) to charge the designated payment method for the subscription fees.
                    </p>
                    <p className="privacy-main-text">
                        Your subscription fees may be billed on a recurring basis according to the applicable duration or period (e.g., monthly, annually). For the avoidance of doubt, your subscription may automatically renew at the end of each billing cycle unless you cancel it before that date (you can cancel your subscription on the billing page). By not cancelling your subscription before the end date, you authorise us to charge the next subscription fees from your payment method.
                    </p>
                    <p className="privacy-main-text">
                        Our subscription fees, features and plans may be changed at any time. Any changes will be communicated in advance and will not affect any current or active subscription.
                    </p>
                    <div className="grey-tab">
                        <p className="privacy-main-bold">
                            PLEASE BE AWARE THAT WE DO NOT PROVIDE REFUNDS EXCEPT WHERE REQUIRED BY LAW.
                        </p>
                    </div>
                </div>
                <div className="privacy-frame-32">
                    <h2 className="privacy-main-header">Term and Termination</h2>
                    <p className="privacy-main-text">
                        These Terms commence when you create an account and continue until you close your account or stop using Tylo.ai. You may terminate your account at any time by using the “Delete Account” button under your account management area.
                    </p>
                    <p className="privacy-main-bold">We also may terminate your account at any time for any reason, including if:</p>
                    <div className="grey-container">
                        <div className="grey-tab-full">
                            <div className="circle-check">
                            <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="https://www.w3.org/2000/svg">
    <path d="M16.1078 0.901957C15.8102 0.736394 15.483 0.631107 15.1447 0.592116C14.8065 0.553125 14.4638 0.581194 14.1364 0.67472C13.809 0.768245 13.5033 0.925392 13.2367 1.13717C12.9701 1.34895 12.7478 1.61121 12.5826 1.90896L7.77052 10.5689L5.01649 7.81489C4.77739 7.56732 4.49137 7.36985 4.17514 7.23401C3.8589 7.09816 3.51877 7.02666 3.17461 7.02367C2.83044 7.02068 2.48912 7.08626 2.17057 7.21659C1.85202 7.34692 1.56262 7.53939 1.31924 7.78276C1.07587 8.02613 0.883407 8.31553 0.753078 8.63408C0.622748 8.95263 0.557166 9.29395 0.560156 9.63812C0.563147 9.98229 0.634651 10.3224 0.770497 10.6386C0.906342 10.9549 1.10381 11.2409 1.35137 11.48L6.53542 16.6641C7.02531 17.1552 7.68628 17.4248 8.36798 17.4248L8.72698 17.3989C9.12427 17.3433 9.50324 17.1963 9.83406 16.9694C10.1649 16.7425 10.4385 16.4419 10.6334 16.0912L17.1135 4.42711C17.2789 4.12961 17.3841 3.80244 17.4231 3.46428C17.4621 3.12611 17.4341 2.78358 17.3407 2.45623C17.2473 2.12889 17.0904 1.82315 16.8788 1.55646C16.6673 1.28978 16.4053 1.06738 16.1078 0.901957Z" fill="#4476F1"/>
    </svg>  
                            </div>
                                <p className="privacy-main-text"> You breach these Terms and any applicable policy or rules</p>     
                        </div>
                        <div className="grey-tab-full">
                            <div className="circle-check">
                            <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="https://www.w3.org/2000/svg">
    <path d="M16.1078 0.901957C15.8102 0.736394 15.483 0.631107 15.1447 0.592116C14.8065 0.553125 14.4638 0.581194 14.1364 0.67472C13.809 0.768245 13.5033 0.925392 13.2367 1.13717C12.9701 1.34895 12.7478 1.61121 12.5826 1.90896L7.77052 10.5689L5.01649 7.81489C4.77739 7.56732 4.49137 7.36985 4.17514 7.23401C3.8589 7.09816 3.51877 7.02666 3.17461 7.02367C2.83044 7.02068 2.48912 7.08626 2.17057 7.21659C1.85202 7.34692 1.56262 7.53939 1.31924 7.78276C1.07587 8.02613 0.883407 8.31553 0.753078 8.63408C0.622748 8.95263 0.557166 9.29395 0.560156 9.63812C0.563147 9.98229 0.634651 10.3224 0.770497 10.6386C0.906342 10.9549 1.10381 11.2409 1.35137 11.48L6.53542 16.6641C7.02531 17.1552 7.68628 17.4248 8.36798 17.4248L8.72698 17.3989C9.12427 17.3433 9.50324 17.1963 9.83406 16.9694C10.1649 16.7425 10.4385 16.4419 10.6334 16.0912L17.1135 4.42711C17.2789 4.12961 17.3841 3.80244 17.4231 3.46428C17.4621 3.12611 17.4341 2.78358 17.3407 2.45623C17.2473 2.12889 17.0904 1.82315 16.8788 1.55646C16.6673 1.28978 16.4053 1.06738 16.1078 0.901957Z" fill="#4476F1"/>
    </svg>  
                            </div>
                                <p className="privacy-main-text"> We are required by law to do so</p>  
                        </div>
                        <div className="grey-tab-full">
                            <div className="circle-check">
                            <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="https://www.w3.org/2000/svg">
    <path d="M16.1078 0.901957C15.8102 0.736394 15.483 0.631107 15.1447 0.592116C14.8065 0.553125 14.4638 0.581194 14.1364 0.67472C13.809 0.768245 13.5033 0.925392 13.2367 1.13717C12.9701 1.34895 12.7478 1.61121 12.5826 1.90896L7.77052 10.5689L5.01649 7.81489C4.77739 7.56732 4.49137 7.36985 4.17514 7.23401C3.8589 7.09816 3.51877 7.02666 3.17461 7.02367C2.83044 7.02068 2.48912 7.08626 2.17057 7.21659C1.85202 7.34692 1.56262 7.53939 1.31924 7.78276C1.07587 8.02613 0.883407 8.31553 0.753078 8.63408C0.622748 8.95263 0.557166 9.29395 0.560156 9.63812C0.563147 9.98229 0.634651 10.3224 0.770497 10.6386C0.906342 10.9549 1.10381 11.2409 1.35137 11.48L6.53542 16.6641C7.02531 17.1552 7.68628 17.4248 8.36798 17.4248L8.72698 17.3989C9.12427 17.3433 9.50324 17.1963 9.83406 16.9694C10.1649 16.7425 10.4385 16.4419 10.6334 16.0912L17.1135 4.42711C17.2789 4.12961 17.3841 3.80244 17.4231 3.46428C17.4621 3.12611 17.4341 2.78358 17.3407 2.45623C17.2473 2.12889 17.0904 1.82315 16.8788 1.55646C16.6673 1.28978 16.4053 1.06738 16.1078 0.901957Z" fill="#4476F1"/>
    </svg>  
                            </div>
                                <p className="privacy-main-text"> You violate any applicable laws or regulations</p>  
                        </div>
                        <div className="grey-tab-full">
                            <div className="circle-check">
                            <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="https://www.w3.org/2000/svg">
    <path d="M16.1078 0.901957C15.8102 0.736394 15.483 0.631107 15.1447 0.592116C14.8065 0.553125 14.4638 0.581194 14.1364 0.67472C13.809 0.768245 13.5033 0.925392 13.2367 1.13717C12.9701 1.34895 12.7478 1.61121 12.5826 1.90896L7.77052 10.5689L5.01649 7.81489C4.77739 7.56732 4.49137 7.36985 4.17514 7.23401C3.8589 7.09816 3.51877 7.02666 3.17461 7.02367C2.83044 7.02068 2.48912 7.08626 2.17057 7.21659C1.85202 7.34692 1.56262 7.53939 1.31924 7.78276C1.07587 8.02613 0.883407 8.31553 0.753078 8.63408C0.622748 8.95263 0.557166 9.29395 0.560156 9.63812C0.563147 9.98229 0.634651 10.3224 0.770497 10.6386C0.906342 10.9549 1.10381 11.2409 1.35137 11.48L6.53542 16.6641C7.02531 17.1552 7.68628 17.4248 8.36798 17.4248L8.72698 17.3989C9.12427 17.3433 9.50324 17.1963 9.83406 16.9694C10.1649 16.7425 10.4385 16.4419 10.6334 16.0912L17.1135 4.42711C17.2789 4.12961 17.3841 3.80244 17.4231 3.46428C17.4621 3.12611 17.4341 2.78358 17.3407 2.45623C17.2473 2.12889 17.0904 1.82315 16.8788 1.55646C16.6673 1.28978 16.4053 1.06738 16.1078 0.901957Z" fill="#4476F1"/>
    </svg>  
                            </div>
                                <p className="privacy-main-text"> Your account remains inactive for a specified period of time.</p>  
                        </div>
                    </div>
                </div>
                <div className="privacy-frame-32">
                    <h2 className="privacy-main-header">Survival of Term</h2>
                    <p className="privacy-main-text">
                        The termination or expiration of your use of Tylo.ai does not affect the continued applicability and binding nature of certain provisions outlined in these Terms. These provisions may include Intellectual Property Ownership, Prohibited Uses, Third-Party Services, Disclaimer of Warranties, Limitation of Liability, Indemnification, Governing Law and Jurisdiction, General Terms, etc.
                    </p>
                </div>
                <div className="privacy-frame-32">
                    <h2 className="privacy-main-header">Electronic Communications</h2>
                    <p className="privacy-main-text">
                        By using Tylo.ai, you consent to receive communications from us electronically. These electronic communications may include notices, updates, alerts and information about your account or changes to Tylo.ai. We may use the email address associated with your account to send you important information about Tylo.ai.
                    </p>
                    <p className="privacy-main-text">
                        By using Tylo.ai, you agree that all agreements, notices, disclosures and other communications that we provide electronically satisfy any legal requirement that such communications be in writing.
                    </p>
                </div>
                <div className="privacy-frame-32">
                    <h2 className="privacy-main-header">Disclaimer of Warranties</h2>
                    <p className="privacy-main-text">
                        THE CONTENT, INFORMATION, MATERIALS AND FEATURES ON TYLO.AI ARE PROVIDED “AS IS” AND “AS AVAILABLE” WITHOUT WARRANTIES OF ANY KIND – WHETHER EXPRESSED OR IMPLIED. TO THE FULLEST EXTENT PERMISSIBLE BY LAW, TYLO AI LTD DISCLAIMS ALL WARRANTIES OF MERCHANTABILITY, ACCURACY, FITNESS FOR A PURPOSE, TITLE AND NON-INFRINGEMENT.
                    </p>
                    <p className="privacy-main-text">
                        WE DO NOT WARRANT THAT TYLO.AI IS ACCURATE, RELIABLE AND SUITABLE FOR YOUR NEEDS OR THAT IT WILL MEET YOUR EXPECTATIONS. WE DO NOT WARRANT THAT TYLO.AI WILL BE WITHOUT ERRORS OR WILL RUN WITHOUT INTERRUPTIONS. WE FURTHER MAKE NO WARRANTIES THAT TYLO.AI IS FREE FROM VIRUSES, BUGS OR OTHER HARMFUL COMPONENTS OR THAT IT IS SECURE.
                    </p>
                </div>
                <div className="privacy-frame-32">
                    <h2 className="privacy-main-header">Limitation of Liability</h2>
                    <p className="privacy-main-text">
                        UNDER NO CIRCUMSTANCES SHALL TYLO AI LTD OR ITS OWNERS, EMPLOYEES, DIRECTORS, AGENTS OR AFFILIATES BE LIABLE TO YOU OR ANY THIRD PARTY FOR ANY INDIRECT, INCIDENTAL, CONSEQUENTIAL OR PUNITIVE DAMAGES THAT MAY ARISE FROM YOUR USE OF TYLO.AI (INCLUDING DAMAGES OF LOSS OF PROFIT, DATA, TIME, INTEGRITY, GOODWILL OR ANY INTANGIBLE LOSSES) – EVEN IF TYLO AI LTD MAY HAVE BEEN HINTED OF THE POSSIBILITY.
                    </p>
                    <p className="privacy-main-text">
                        IN THE EVENT THAT WE ARE FOUND LIABLE FOR ANY DAMAGE WHATSOEVER RELATED TO TYLO.AI AND THESE TERMS, OUR MAXIMUM LIABILITY SHALL NOT EXCEED THE GREATER BETWEEN THE SUM OF YOUR SUBSCRIPTION FEES IN THE PREVIOUS 3 MONTHS BEFORE THE CLAIM OR A FIXED SUM OF £100.
                    </p>
                    <div className="grey-tab">
                        <p className="privacy-main-bold">
                            Certain location laws do not allow the limitations on implied warranties. If this applies to you, some or all of the limitations above may not apply to you, and you may have added rights.
                        </p>
                    </div>
                </div>
                <div className="privacy-frame-32">
                    <h2 className="privacy-main-header">Indemnification</h2>
                    <p className="privacy-main-text">
                        You hereby agree to defend, indemnify and hold harmless Tylo AI Ltd and its licensors, officers, employees, agents and affiliates harmless from and against any claims, liabilities, damages, losses and expenses (including without limitation, reasonable legal and accounting fees) arising out of or in any way connected with your use of Tylo.ai, your violation of these Terms or your violation of any rights of another party.
                    </p>
                </div>
                <div className="privacy-frame-32">
                    <h2 className="privacy-main-header">Governing Law and Jurisdiction</h2>
                    <p className="privacy-main-text">
                        These Terms and your use of Tylo.ai shall be governed by and construed in accordance with the English law without regard to its conflict of law principles. Any legal action or proceeding arising out of or related to these Terms or your use of Tylo.ai shall be brought exclusively in the courts located in England. You consent to the jurisdiction of such courts and waive any objection to the laying of venue in any such court.
                    </p>
                </div>
                <div className="privacy-frame-32">
                    <h2 className="privacy-main-header">Third-Party Services</h2>
                    <p className="privacy-main-text">
                        By using Tylo.ai, you acknowledge and agree that it may contain links to third-party websites, applications or services. We do not endorse or assume responsibility for the content or practices of any third-party websites. These Terms do not govern their practices. Your use of third-party links is at your own risk, and we are not responsible for the availability or accuracy of content on linked third-party websites.
                    </p>
                </div>
                <div className="privacy-frame-32">
                    <h2 className="privacy-main-header">Updates and Modifications</h2>
                    <p className="privacy-main-text">
                        We may update and modify these Terms periodically, and it is your responsibility to review them regularly. Any updates or modifications to these Terms shall go live immediately, and the Effective Date above shall be updated to confirm the date of update.
                    </p>
                    <p className="privacy-main-text">
                        We may, however, notify you of updates or modifications by displaying a notice banner or mailing your email address. Nevertheless, you should frequently review this page for updates.
                    </p>
                    <p className="privacy-main-text">
                        Your continued use of Tylo.ai after any modifications indicate your acceptance of the updated Terms. If you do not agree with the changes, please cease using Tylo.ai.
                    </p>
                </div>
                <div className="privacy-frame-32">
                    <h2 className="privacy-main-header">General Terms</h2>
                    <div className="grey-container">
                        
                            <div className="grey-row">
                                <div className="grey-sub-container">
                                    <div className="circle-check">
                                    <svg width="22" height="26" viewBox="0 0 22 26" fill="none" xmlns="https://www.w3.org/2000/svg">
  <path d="M7.56266 14.1459H14.4377M7.56266 18.7292H11.0002M1.8335 3.83335V22.1667C1.8335 22.7745 2.07494 23.3574 2.50471 23.7871C2.93448 24.2169 3.51738 24.4584 4.12516 24.4584H17.8752C18.483 24.4584 19.0658 24.2169 19.4956 23.7871C19.9254 23.3574 20.1668 22.7745 20.1668 22.1667V8.80856C20.1668 8.50327 20.1058 8.20105 19.9873 7.91968C19.8688 7.6383 19.6954 7.38342 19.477 7.17002L14.3895 2.19481C13.9614 1.77619 13.3864 1.54176 12.7877 1.54169H4.12516C3.51738 1.54169 2.93448 1.78313 2.50471 2.2129C2.07494 2.64267 1.8335 3.22557 1.8335 3.83335Z" stroke="#4476F1" stroke-width="2.29167" stroke-linecap="round" stroke-linejoin="round"/>
  <path d="M13.292 1.54175V6.12508C13.292 6.73287 13.5334 7.31576 13.9632 7.74553C14.393 8.17531 14.9759 8.41675 15.5837 8.41675H20.167" stroke="#4476F1" stroke-width="2.29167" stroke-linejoin="round"/>
</svg>
                                    </div>
                                    <p className="privacy-sub-bold">Entire Agreement</p>
                                </div>
                                <p className="privacy-main-text">
                                These Terms constitute the entire agreement between you and Tylo AI Ltd regarding your use of Tylo.ai, and they supersede any prior agreements or understandings, whether oral or written.
                                </p>
                            </div>
                    
                        
                            <div className="grey-row">
                                <div className="grey-sub-container">
                                    <div className="circle-check">
                                    <svg width="22" height="26" viewBox="0 0 22 26" fill="none" xmlns="https://www.w3.org/2000/svg">
  <path d="M7.56266 14.1459H14.4377M7.56266 18.7292H11.0002M1.8335 3.83335V22.1667C1.8335 22.7745 2.07494 23.3574 2.50471 23.7871C2.93448 24.2169 3.51738 24.4584 4.12516 24.4584H17.8752C18.483 24.4584 19.0658 24.2169 19.4956 23.7871C19.9254 23.3574 20.1668 22.7745 20.1668 22.1667V8.80856C20.1668 8.50327 20.1058 8.20105 19.9873 7.91968C19.8688 7.6383 19.6954 7.38342 19.477 7.17002L14.3895 2.19481C13.9614 1.77619 13.3864 1.54176 12.7877 1.54169H4.12516C3.51738 1.54169 2.93448 1.78313 2.50471 2.2129C2.07494 2.64267 1.8335 3.22557 1.8335 3.83335Z" stroke="#4476F1" stroke-width="2.29167" stroke-linecap="round" stroke-linejoin="round"/>
  <path d="M13.292 1.54175V6.12508C13.292 6.73287 13.5334 7.31576 13.9632 7.74553C14.393 8.17531 14.9759 8.41675 15.5837 8.41675H20.167" stroke="#4476F1" stroke-width="2.29167" stroke-linejoin="round"/>
</svg>
                                    </div>
                                    <p className="privacy-sub-bold">Severability</p>
                                </div>
                                <p className="privacy-main-text">
                                If any provision of these Terms is found to be unenforceable or invalid, that provision will be limited or eliminated to the minimum extent necessary, and the remaining provisions will remain in full force and effect.
                                </p>
                            </div>

                            <div className="grey-row">
                                <div className="grey-sub-container">
                                    <div className="circle-check">
                                    <svg width="22" height="26" viewBox="0 0 22 26" fill="none" xmlns="https://www.w3.org/2000/svg">
  <path d="M7.56266 14.1459H14.4377M7.56266 18.7292H11.0002M1.8335 3.83335V22.1667C1.8335 22.7745 2.07494 23.3574 2.50471 23.7871C2.93448 24.2169 3.51738 24.4584 4.12516 24.4584H17.8752C18.483 24.4584 19.0658 24.2169 19.4956 23.7871C19.9254 23.3574 20.1668 22.7745 20.1668 22.1667V8.80856C20.1668 8.50327 20.1058 8.20105 19.9873 7.91968C19.8688 7.6383 19.6954 7.38342 19.477 7.17002L14.3895 2.19481C13.9614 1.77619 13.3864 1.54176 12.7877 1.54169H4.12516C3.51738 1.54169 2.93448 1.78313 2.50471 2.2129C2.07494 2.64267 1.8335 3.22557 1.8335 3.83335Z" stroke="#4476F1" stroke-width="2.29167" stroke-linecap="round" stroke-linejoin="round"/>
  <path d="M13.292 1.54175V6.12508C13.292 6.73287 13.5334 7.31576 13.9632 7.74553C14.393 8.17531 14.9759 8.41675 15.5837 8.41675H20.167" stroke="#4476F1" stroke-width="2.29167" stroke-linejoin="round"/>
</svg>
                                    </div>
                                    <p className="privacy-sub-bold">Waiver</p>
                                </div>
                                <p className="privacy-main-text">
                                The failure of Tylo AI Ltd to enforce any right or provision of these Terms shall not be deemed a waiver of such right or provision. No waiver shall be binding unless they be in writing.
                                </p>
                            </div>
                            
                    </div>
                    <div className="grey-container">
                        
                            <div className="grey-row">
                                <div className="grey-sub-container">
                                    <div className="circle-check">
                                    <svg width="22" height="26" viewBox="0 0 22 26" fill="none" xmlns="https://www.w3.org/2000/svg">
  <path d="M7.56266 14.1459H14.4377M7.56266 18.7292H11.0002M1.8335 3.83335V22.1667C1.8335 22.7745 2.07494 23.3574 2.50471 23.7871C2.93448 24.2169 3.51738 24.4584 4.12516 24.4584H17.8752C18.483 24.4584 19.0658 24.2169 19.4956 23.7871C19.9254 23.3574 20.1668 22.7745 20.1668 22.1667V8.80856C20.1668 8.50327 20.1058 8.20105 19.9873 7.91968C19.8688 7.6383 19.6954 7.38342 19.477 7.17002L14.3895 2.19481C13.9614 1.77619 13.3864 1.54176 12.7877 1.54169H4.12516C3.51738 1.54169 2.93448 1.78313 2.50471 2.2129C2.07494 2.64267 1.8335 3.22557 1.8335 3.83335Z" stroke="#4476F1" stroke-width="2.29167" stroke-linecap="round" stroke-linejoin="round"/>
  <path d="M13.292 1.54175V6.12508C13.292 6.73287 13.5334 7.31576 13.9632 7.74553C14.393 8.17531 14.9759 8.41675 15.5837 8.41675H20.167" stroke="#4476F1" stroke-width="2.29167" stroke-linejoin="round"/>
</svg>
                                    </div>
                                    <p className="privacy-sub-bold">Assignment</p>
                                </div>
                                <p className="privacy-main-text">
                                Tylo AI Ltd may assign or transfer its rights under these Terms, in whole or in part, without your consent. You may not assign or transfer these Terms without our prior written consent.
                                </p>
                            </div>
                    
                        
                            <div className="grey-row">
                                <div className="grey-sub-container">
                                    <div className="circle-check">
                                    <svg width="22" height="26" viewBox="0 0 22 26" fill="none" xmlns="https://www.w3.org/2000/svg">
  <path d="M7.56266 14.1459H14.4377M7.56266 18.7292H11.0002M1.8335 3.83335V22.1667C1.8335 22.7745 2.07494 23.3574 2.50471 23.7871C2.93448 24.2169 3.51738 24.4584 4.12516 24.4584H17.8752C18.483 24.4584 19.0658 24.2169 19.4956 23.7871C19.9254 23.3574 20.1668 22.7745 20.1668 22.1667V8.80856C20.1668 8.50327 20.1058 8.20105 19.9873 7.91968C19.8688 7.6383 19.6954 7.38342 19.477 7.17002L14.3895 2.19481C13.9614 1.77619 13.3864 1.54176 12.7877 1.54169H4.12516C3.51738 1.54169 2.93448 1.78313 2.50471 2.2129C2.07494 2.64267 1.8335 3.22557 1.8335 3.83335Z" stroke="#4476F1" stroke-width="2.29167" stroke-linecap="round" stroke-linejoin="round"/>
  <path d="M13.292 1.54175V6.12508C13.292 6.73287 13.5334 7.31576 13.9632 7.74553C14.393 8.17531 14.9759 8.41675 15.5837 8.41675H20.167" stroke="#4476F1" stroke-width="2.29167" stroke-linejoin="round"/>
</svg>
                                    </div>
                                    <p className="privacy-sub-bold">Headings</p>
                                </div>
                                <p className="privacy-main-text">
                                The headings used in these Terms are for convenience only and do not affect the interpretation of the provisions.
                                </p>
                            </div>

                            <div className="grey-row">
                                <div className="grey-sub-container">
                                    <div className="circle-check">
                                    <svg width="22" height="26" viewBox="0 0 22 26" fill="none" xmlns="https://www.w3.org/2000/svg">
  <path d="M7.56266 14.1459H14.4377M7.56266 18.7292H11.0002M1.8335 3.83335V22.1667C1.8335 22.7745 2.07494 23.3574 2.50471 23.7871C2.93448 24.2169 3.51738 24.4584 4.12516 24.4584H17.8752C18.483 24.4584 19.0658 24.2169 19.4956 23.7871C19.9254 23.3574 20.1668 22.7745 20.1668 22.1667V8.80856C20.1668 8.50327 20.1058 8.20105 19.9873 7.91968C19.8688 7.6383 19.6954 7.38342 19.477 7.17002L14.3895 2.19481C13.9614 1.77619 13.3864 1.54176 12.7877 1.54169H4.12516C3.51738 1.54169 2.93448 1.78313 2.50471 2.2129C2.07494 2.64267 1.8335 3.22557 1.8335 3.83335Z" stroke="#4476F1" stroke-width="2.29167" stroke-linecap="round" stroke-linejoin="round"/>
  <path d="M13.292 1.54175V6.12508C13.292 6.73287 13.5334 7.31576 13.9632 7.74553C14.393 8.17531 14.9759 8.41675 15.5837 8.41675H20.167" stroke="#4476F1" stroke-width="2.29167" stroke-linejoin="round"/>
</svg>
                                    </div>
                                    <p className="privacy-sub-bold">No Third-Party Beneficiaries</p>
                                </div>
                                <p className="privacy-main-text">
                                These Terms do not confer any rights or benefits upon any third party unless expressly stated otherwise.
                                </p>
                            </div>
                            
                    </div>
                    <div className="grey-row">
                        <div className="grey-sub-container">
                            <div className="circle-check">
                            <svg width="22" height="26" viewBox="0 0 22 26" fill="none" xmlns="https://www.w3.org/2000/svg">
  <path d="M7.56266 14.1459H14.4377M7.56266 18.7292H11.0002M1.8335 3.83335V22.1667C1.8335 22.7745 2.07494 23.3574 2.50471 23.7871C2.93448 24.2169 3.51738 24.4584 4.12516 24.4584H17.8752C18.483 24.4584 19.0658 24.2169 19.4956 23.7871C19.9254 23.3574 20.1668 22.7745 20.1668 22.1667V8.80856C20.1668 8.50327 20.1058 8.20105 19.9873 7.91968C19.8688 7.6383 19.6954 7.38342 19.477 7.17002L14.3895 2.19481C13.9614 1.77619 13.3864 1.54176 12.7877 1.54169H4.12516C3.51738 1.54169 2.93448 1.78313 2.50471 2.2129C2.07494 2.64267 1.8335 3.22557 1.8335 3.83335Z" stroke="#4476F1" stroke-width="2.29167" stroke-linecap="round" stroke-linejoin="round"/>
  <path d="M13.292 1.54175V6.12508C13.292 6.73287 13.5334 7.31576 13.9632 7.74553C14.393 8.17531 14.9759 8.41675 15.5837 8.41675H20.167" stroke="#4476F1" stroke-width="2.29167" stroke-linejoin="round"/>
</svg>
                            </div>
                            <p className="privacy-sub-bold">Force Majeure</p>
                        </div>
                        <p className="privacy-main-text">
                        Tylo AI Ltd shall not be liable to you for any failure or delay in the performance of its obligations due to events beyond its reasonable control, including but not limited to acts of God, war, terrorism, pandemics, labor disputes or technical failures.
                        </p>
                    </div>
                </div>
            </div>
            <div className="all-container">
                <div className="all-rights-text">2024 Tylo AI Ltd. All rights reserved. </div>   
                <div className="all-rights-highlight" onClick={handleTOUClick}>
                    Terms of use  
                </div>
                <div className="all-rights-highlight" onClick={handlePrivacyPolicyClick}>
                    Privacy Policy 
                </div> 
                <a href="https://www.linkedin.com/company/tyloai/" target="_blank" rel="noopener noreferrer">
                <svg width="39" height="39" viewBox="0 0 39 39" fill="none" xmlns="https://www.w3.org/2000/svg">
  <g clip-path="url(#clip0_2801_871)">
    <path d="M29.4609 0.75H9.53906C4.685 0.75 0.75 4.685 0.75 9.53906V29.4609C0.75 34.315 4.685 38.25 9.53906 38.25H29.4609C34.315 38.25 38.25 34.315 38.25 29.4609V9.53906C38.25 4.685 34.315 0.75 29.4609 0.75Z" fill="white"/>
    <path d="M29.4609 0.75H9.53906C4.685 0.75 0.75 4.685 0.75 9.53906V29.4609C0.75 34.315 4.685 38.25 9.53906 38.25H29.4609C34.315 38.25 38.25 34.315 38.25 29.4609V9.53906C38.25 4.685 34.315 0.75 29.4609 0.75Z" fill="#4476F1"/>
    <path d="M27.8079 32.6375H32.0955C32.2508 32.6375 32.3998 32.5757 32.5097 32.4659C32.6196 32.356 32.6814 32.207 32.6814 32.0517L32.6836 22.9928C32.6836 18.258 31.6633 14.6186 26.1302 14.6186C24.0268 14.5403 22.0433 15.6246 20.9729 17.4352C20.9677 17.444 20.9597 17.4508 20.9502 17.4546C20.9407 17.4585 20.9303 17.4591 20.9204 17.4564C20.9105 17.4538 20.9018 17.4479 20.8955 17.4398C20.8893 17.4317 20.8858 17.4218 20.8857 17.4116V15.6416C20.8857 15.4862 20.824 15.3372 20.7141 15.2273C20.6042 15.1174 20.4552 15.0557 20.2998 15.0557H16.2309C16.0755 15.0557 15.9265 15.1174 15.8166 15.2273C15.7067 15.3372 15.645 15.4862 15.645 15.6416V32.0508C15.645 32.2062 15.7067 32.3552 15.8166 32.4651C15.9265 32.575 16.0755 32.6367 16.2309 32.6367H20.5182C20.6736 32.6367 20.8226 32.575 20.9325 32.4651C21.0424 32.3552 21.1042 32.2062 21.1042 32.0508V23.9395C21.1042 21.646 21.5392 19.4249 24.3826 19.4249C27.1856 19.4249 27.2219 22.0493 27.2219 24.088V32.0515C27.2219 32.2069 27.2837 32.3559 27.3935 32.4658C27.5034 32.5757 27.6525 32.6375 27.8079 32.6375ZM6.31641 9.48457C6.31641 11.2225 7.74712 12.6524 9.48516 12.6524C11.2228 12.6523 12.6526 11.2214 12.6526 9.48384C12.6523 7.74624 11.2223 6.31641 9.48457 6.31641C7.74639 6.31641 6.31641 7.74668 6.31641 9.48457ZM7.33579 32.6375H11.6288C11.7842 32.6375 11.9332 32.5757 12.0431 32.4658C12.153 32.3559 12.2147 32.2069 12.2147 32.0515V15.6416C12.2147 15.4862 12.153 15.3372 12.0431 15.2273C11.9332 15.1174 11.7842 15.0557 11.6288 15.0557H7.33579C7.18039 15.0557 7.03136 15.1174 6.92147 15.2273C6.81159 15.3372 6.74985 15.4862 6.74985 15.6416V32.0515C6.74985 32.2069 6.81159 32.3559 6.92147 32.4658C7.03136 32.5757 7.18039 32.6375 7.33579 32.6375Z" fill="white"/>
  </g>
  <defs>
    <clipPath id="clip0_2801_871">
      <rect width="37.5" height="37.5" fill="white" transform="translate(0.75 0.75)"/>
    </clipPath>
  </defs>
</svg>
</a>
            </div>
        </div>
    )
}

export default Tou;